import StepsManager from './steps';
import Storage from './storage';
import Logger from './logger';
import Canvas from './canvas';
import QRCode from './qrcode';
import Dom from './dom';
import Loader from './loader';
import Transformable from './transformable';
import Camera from './camera';
import Calc from './calc';
import templateHtml from './inject';

import stepTakePhotoWithObject from './steps/takePhotoWithObject';
import stepMeasureCard from './steps/measureCard';
import stepMeasureGlasses from './steps/measureGlasses';
import stepTakePhotoWithoutAnything from './steps/takePhotoWithoutAnything';
import stepAdjustPupils from './steps/adjustPupils';
import stepPreviewGlasses from './steps/previewGlasses';

import imgPupil from '../assets/pupil.svg?url';
import imgDragAny from '../assets/drag-any.svg?url';
import imgDragAnyRev from '../assets/drag-any-rev.svg?url';
import imgDragAnyH from '../assets/drag-any-h.svg?url';
import imgDragAnyHRev from '../assets/drag-any-h-rev.svg?url';
import imgDragAnyAngled from '../assets/drag-any-angled.svg?url';
import imgDragAnyAngledRev from '../assets/drag-any-angled-rev.svg?url';
import imgDragHorizontal from '../assets/drag-horizontal.svg?url';
import imgDragHorizontalRev from '../assets/drag-horizontal-rev.svg?url';
import imgDragVertical from '../assets/drag-vertical.svg?url';
import imgDragVerticalRev from '../assets/drag-vertical-rev.svg?url';

function decodeInitParams() {
    try {
        const url = new URL(window.location.href);

        if (url.searchParams.has('nmto_i')) {
            const base64 = url.searchParams.get('nmto_i');
            const decoded = window.atob(base64);
            const payload = JSON.parse(decoded);
            const params = {
                showMainPopup: false,
                glassesWidthMm: 0,
                glassesImageUrl: '',
                debugMode: false,
            };

            if (payload[0]) params.showMainPopup = Number(payload[0]) === 1;
            if (payload[1]) params.glassesWidthMm = Number(payload[1]);
            if (payload[2]) params.glassesImageUrl = payload[2].toString().trim();
            if (payload[3]) params.debugMode = Number(payload[3]) === 1;

            return params;
        }
    } catch (error) {
        Logger.error('parsePageUrl() error:', error);
    }

    return {};
}

function encodeInitParams(params = {}) {
    try {
        const url = new URL(window.location.href);
        const jsonString = JSON.stringify(params);
        const base64 = window.btoa(jsonString);

        url.searchParams.set('nmto_i', base64);

        return url.href;
    } catch (error) {
        Logger.error('encodeInitParams() error:', error);
    }

    return '';
}

async function loadImage(url = '', name = '') {
    return new Promise((resolve, reject) => {
        const image = new Image();
        image.crossOrigin = 'anonymous';
        image.addEventListener('load', () => resolve([image, name]));
        image.addEventListener('error', (error) => reject(error));
        image.src = url;
    });
}

async function preloadAssets() {
    const assets = await Promise.all([
        loadImage(imgPupil, 'pupil'),
        loadImage(imgDragAny, 'drag-any'),
        loadImage(imgDragAnyRev, 'drag-any-rev'),
        loadImage(imgDragAnyH, 'drag-any-h'),
        loadImage(imgDragAnyHRev, 'drag-any-h-rev'),
        loadImage(imgDragAnyAngled, 'drag-any-angled'),
        loadImage(imgDragAnyAngledRev, 'drag-any-angled-rev'),
        loadImage(imgDragHorizontal, 'drag-horizontal'),
        loadImage(imgDragHorizontalRev, 'drag-horizontal-rev'),
        loadImage(imgDragVertical, 'drag-vertical'),
        loadImage(imgDragVerticalRev, 'drag-vertical-rev'),
    ]);

    assets.forEach((asset) => {
        const assetObject = asset[0];
        const assetName = asset[1];
        Storage.set('assets/' + assetName, assetObject);
        Logger.debug('Preload asset', assetName);
    });
}

export default class Tryon {
    #inited;
    #debugMode;
    #initParams;

    constructor() {
        this.#inited = false;
        this.#debugMode = false;
        this.#initParams = {};
    }

    async init(debugMode = false, callback) {
        if (this.#inited) return false;
        this.#debugMode = debugMode;
        this.#initParams = decodeInitParams();
        if (this.#initParams.debugMode) this.#debugMode = true;

        Logger.toggleDebugMode(this.#debugMode);
        Logger.debug('[Tryon init]');
        Logger.debug('Parsed url params:', this.#initParams);

        document.body.insertAdjacentHTML('beforeend', templateHtml);

        await preloadAssets();
        if (this.#debugMode) this._dumpStorage('assets');

        Dom.init();
        Logger.debug('Dom root', Dom.root());
        Logger.debug('Dom intro root', Dom.introRoot());
        Array.from(document.querySelectorAll('[data-inject-build-version]')).forEach((element) => {
            let version = window._nmto_build_version ? window._nmto_build_version : 'N/A';
            Logger.debug('Build version:', version);
            element.textContent = 'Build version: ' + version;

            if (this.#debugMode) {
                element.style.display = 'block';
            } else {
                element.style.display = 'none';
            }
        });

        StepsManager.register(1, 'stepTakePhotoWithObject', stepTakePhotoWithObject);
        StepsManager.register(2, 'stepMeasureCard', stepMeasureCard);
        StepsManager.register(2, 'stepMeasureGlasses', stepMeasureGlasses);
        StepsManager.register(3, 'stepTakePhotoWithoutAnything', stepTakePhotoWithoutAnything);
        StepsManager.register(4, 'stepAdjustPupils', stepAdjustPupils);
        StepsManager.register(5, 'stepPreviewGlasses', stepPreviewGlasses);
        if (this.#debugMode) StepsManager._dumpSteps();

        Transformable.add(
            'pupils/measureCard/leftPupil',
            [{ type: 'translate', pos: 'left-top', offsetX: -10, offsetY: -10, enabled: true }],
            true,
        );

        Transformable.add(
            'pupils/measureCard/rightPupil',
            [{ type: 'translate', pos: 'right-top', offsetX: -10, offsetY: -10, enabled: true }],
            true,
        );

        Transformable.add(
            'pupils/measureGlasses/leftPupil',
            [{ type: 'translate', pos: 'left-top', offsetX: -10, offsetY: -10, enabled: true }],
            true,
        );

        Transformable.add(
            'pupils/measureGlasses/rightPupil',
            [{ type: 'translate', pos: 'right-top', offsetX: -10, offsetY: -10, enabled: true }],
            true,
        );

        Transformable.add(
            'pupils/adjustPupils/leftPupil',
            [{ type: 'translate', pos: 'left-top', offsetX: -10, offsetY: -10, enabled: true }],
            true,
        );

        Transformable.add(
            'pupils/adjustPupils/rightPupil',
            [{ type: 'translate', pos: 'right-top', offsetX: -10, offsetY: -10, enabled: true }],
            true,
        );

        Transformable.add('objects/cardOutline', [
            { type: 'custom-hs-vt', pos: 'left-center', offsetX: 3, offsetY: 0, enabled: true },
            { type: 'custom-hs-vt', pos: 'right-center', offsetX: 3, offsetY: 0, enabled: true },
        ]);

        Transformable.add('objects/glassesOutline', [
            { type: 'scale', pos: 'left-center', offsetX: 6, offsetY: 0, enabled: true },
            { type: 'scale', pos: 'right-center', offsetX: 6, offsetY: 0, enabled: true },
            { type: 'translate', pos: 'center-top', offsetX: 0, offsetY: -35, enabled: true },
        ]);

        Transformable.add('objects/glassesPreview', [
            { type: 'translate', pos: 'center-top', offsetX: 0, offsetY: 0, enabled: true },
            { type: 'translate', pos: 'all', offsetX: 0, offsetY: 0, enabled: true },
        ]);

        Loader.init();
        Canvas.init(this.#debugMode);

        Storage.set('objects/glassesPreview/x', Canvas.width() / 2 - 280 / 2);
        Storage.set('objects/glassesPreview/y', 200);
        Storage.set('objects/glassesPreview/w', 280);
        Storage.set('objects/glassesPreview/h', 100);

        window.addEventListener('resize', (event) => {
            Canvas.handleResizeEvent(event);
        });

        Dom.getByIdFromIntro('main-popup/closeBtn').addEventListener('click', () => {
            document.body.classList.remove('tryon-blockscroll');
            Dom.root().classList.remove('active');
            Dom.introRoot().classList.remove('active');
            document.querySelector('meta[name="viewport"]').content = Storage.get('metaViewportBackup');
        });

        Dom.getByIdFromIntro('main-popup/cardBtn').addEventListener('click', () => {
            Dom.introRoot().classList.remove('active');
            Dom.root().classList.add('active');
            StepsManager.go('stepTakePhotoWithObject', 'card', {});
        });

        Dom.getByIdFromIntro('main-popup/glassesBtn').addEventListener('click', () => {
            Dom.introRoot().classList.remove('active');
            Dom.root().classList.add('active');
            StepsManager.go('stepTakePhotoWithObject', 'glass', {});
        });

        Dom.getById('stepper/close').addEventListener('click', () => {
            document.body.classList.remove('tryon-blockscroll');
            Dom.root().classList.remove('active');
            Dom.introRoot().classList.remove('active');
            Loader.setLoadingMode();
            Loader.hide();
            document.querySelector('meta[name="viewport"]').content = Storage.get('metaViewportBackup');
            if (!Storage.get('finished')) {
                NMTryon.reset();
            }
        });

        this.#inited = true;
        if (callback) await callback();
        if (this.#initParams.showMainPopup) {
            this.showPopup({
                glassesImageUrl: this.#initParams.glassesImageUrl,
                glassesWidthMm: this.#initParams.glassesWidthMm,
            });
        }
    }

    async showPopup({ glassesImageUrl = '', glassesWidthMm = 100 } = {}) {
        if (!this.#inited) return false;
        Logger.debug('[Show popup]', glassesImageUrl, glassesWidthMm);

        const glassesImage = await loadImage(glassesImageUrl, 'glasses');
        Storage.set('assets/glasses', glassesImage[0]);
        Storage.set('glassesWidthMm', glassesWidthMm);
        Storage.set('metaViewportBackup', document.querySelector('meta[name="viewport"]').content);

        if (Storage.loadGlassesCalcData()) {
            const photoWithoutAnything = await loadImage(
                Storage.get('userPhoto/withoutAnything').dataUrl,
                'tmpPhotoWithoutAnything',
            );
            Storage.set('userPhoto/withoutAnything', {
                ...Storage.get('userPhoto/withoutAnything'),
                imageObject: photoWithoutAnything[0],
            });
            Storage.set('finished', true);
        }

        document.body.classList.add('tryon-blockscroll');
        document.querySelector(
            'meta[name="viewport"]',
        ).content = `height=device-height, width=device-width, initial-scale=1.0, minimum-scale=1.0, maximum-scale=1.0, user-scalable=no, target-densitydpi=device-dpi`;

        if (!Storage.get('finished')) {
            // [showMainPopup, glassesWidthMm, glassesImageUrl, ?debugMode]
            const qrcodeUrl = encodeInitParams([1, glassesWidthMm, glassesImageUrl]);
            const qrcodeDebugUrl = encodeInitParams([1, glassesWidthMm, glassesImageUrl, 1]);

            if (Dom.getByIdFromIntro('qr-code'))
                Dom.getByIdFromIntro('qr-code').src = await QRCode.toDataUrl(qrcodeUrl);
            Dom.getByIdFromIntro('qr-code').dataset.href = qrcodeUrl;
            Dom.getByIdFromIntro('qr-code').dataset.debugHref = qrcodeDebugUrl;
            Dom.getByIdFromIntro('qr-code').title = 'Link: ' + qrcodeUrl;

            Dom.introRoot().classList.add('active');
        } else {
            Dom.root().classList.add('active');
            StepsManager.go('stepPreviewGlasses', Storage.get('flowType'), {});
        }
    }

    async getImage({ glassesImageUrl = '', glassesWidthMm = 100 } = {}) {
        if (!this.#inited) return false;
        Logger.debug('[Get image]', glassesImageUrl, glassesWidthMm);

        const glassesImage = await loadImage(glassesImageUrl, 'glasses');

        if (Storage.loadGlassesCalcData()) {
            const photoWithoutAnything = await loadImage(
                Storage.get('userPhoto/withoutAnything').dataUrl,
                'tmpPhotoWithoutAnything',
            );
            Storage.set('userPhoto/withoutAnything', {
                ...Storage.get('userPhoto/withoutAnything'),
                imageObject: photoWithoutAnything[0],
            });

            Storage.set('assets/glasses', glassesImage[0]);
            Storage.set('glassesWidthMm', glassesWidthMm);

            Canvas.disableDrawLoop();
            Canvas.unsubscribeAll();
            Dom.root().classList.add('active');
            Canvas.resizeToVideo();
            Dom.root().classList.remove('active');

            const glassesData = Storage.getGlassesCalcData();
            const calculatedGlasses = Calc.calculateGlassesDimensions({
                cardPupils: glassesData.pupils.measureCard,
                glassesPupils: glassesData.pupils.measureGlasses,
                adjustPupils: glassesData.pupils.adjustPupils,
                glassesWidthMm: glassesData.glassesWidthMm,
                userGlassesWidthMm: glassesData.userData.glassesWidthMm,
                cardOutlineWidth: glassesData.objects.cardOutline.w,
                glassesOutlineWidth: glassesData.objects.glassesOutline.w,
                flowType: glassesData.flowType,
            });

            let aspectRatio =
                +Storage.get('assets/glasses').naturalWidth / +Storage.get('assets/glasses').naturalHeight;
            Storage.set('objects/glassesPreview/w', Math.round(calculatedGlasses.w));
            Storage.set('objects/glassesPreview/h', Math.round(calculatedGlasses.w / aspectRatio));

            let canvasWidth = Canvas.width();
            let canvasHeight = Canvas.height();
            if (window.devicePixelRatio > 1) {
                canvasWidth /= window.devicePixelRatio;
                canvasHeight /= window.devicePixelRatio;
            }

            Canvas.subscribeToDrawLoop('tmpGetImage', (ctx) => {
                Canvas.clear();

                ctx.drawImage(Storage.get('userPhoto/withoutAnything').imageObject, 0, 0, canvasWidth, canvasHeight);
                Canvas.drawPreviewGlasses('objects/glassesPreview');
            });

            Canvas.enableDrawLoop();
            const dataUrl = await Canvas.toDataURL('image/jpeg', 1);
            Canvas.disableDrawLoop();

            return dataUrl;
        }

        return false;
    }

    _dumpStorage(prefix = '') {
        console.groupCollapsed('Debug storage dump');
        Storage.each(function (value, key) {
            if (key.startsWith(prefix)) console.log(key + ':', value);
        });
        console.groupEnd();
    }

    reset() {
        if (!this.#inited) return false;
        Logger.debug('[Reset request]');

        Camera.pause();
        Camera.closeStream();

        Transformable.disableAll();

        Canvas.disableDrawLoop();
        Canvas.unsubscribeAll();

        Storage.set('finished', false);
        Storage.remove('localStorageReconstructed');
        Storage.remove('userPhoto/withoutAnything');
        Storage.remove('userPhoto/card');
        Storage.remove('userPhoto/glass');
        Storage.clearSavedGlassesCalcData();
    }
}
