import Canvas from '../canvas';
import Storage from '../storage';
import Transformable from '../transformable';
import Dom from '../dom';
import StepsManager from '../steps';
import Calc from '../calc';

let transformableListeners = {};
let handleContinueBtnClick;
let handleStep1BtnClick;
let handleStep3BtnClick;
let drawLoop;
let faceBox;

function callback(props) {
    this.props = props;
    handleContinueBtnClick = _handleContinueBtnClick.bind(this);
    handleStep1BtnClick = _handleStep1BtnClick.bind(this);
    handleStep3BtnClick = _handleStep3BtnClick.bind(this);
    drawLoop = _drawLoop.bind(this);
    transformableListeners = Transformable.getListeners();

    Transformable.enable('pupils/measureCard/leftPupil');
    Transformable.enable('pupils/measureCard/rightPupil');
    Transformable.enable('objects/cardOutline');

    Canvas.resizeToVideo();

    Canvas.subscribeToDrawLoop('stepMeasureCard', drawLoop);
    Canvas.subscribeToEvent('stepMeasureCard', 'mousedown', handleMousedownEvent);
    Canvas.subscribeToEvent('stepMeasureCard', 'touchstart', handleMousedownEvent);
    Canvas.subscribeToEvent('stepMeasureCard', 'mousemove', handleMousemoveEvent);
    Canvas.subscribeToEvent('stepMeasureCard', 'touchmove', handleMousemoveEvent);
    Canvas.subscribeToEvent('stepMeasureCard', 'mouseup', handleMouseupEvent);
    Canvas.subscribeToEvent('stepMeasureCard', 'touchend', handleMouseupEvent);
    Canvas.subscribeToEvent('stepMeasureCard', 'mouseleave', handleMouseleaveEvent);
    Canvas.subscribeToEvent('stepMeasureCard', 'touchcancel', handleMouseleaveEvent);

    faceBox = Calc.cropFaceBox(
        Storage.get('userPhoto/' + this.props.flowType),
        Storage.get('faces/photoWithObject')[0],
        Canvas.width(),
        Canvas.height(),
    );
    Calc.convertPupilsToGlobalCoords('pupils/measureCard', faceBox);

    if (this.props.keepCardPos) {
        Calc.convertCardToGlobalCoords('objects/cardOutline', faceBox);
    } else {
        let canvasWidth = Canvas.width();
        if (window.devicePixelRatio > 1) {
            canvasWidth /= window.devicePixelRatio;
        }

        let cardWidth = Math.round(
            Calc.calculatePupilsDist('pupils/measureCard') + +Storage.get('pupils/measureCard/leftPupil/w') * 2,
        );
        let cardHeight = 53;
        let cardX = Math.round(
            +Storage.get('pupils/measureCard/leftPupil/x') - +Storage.get('pupils/measureCard/leftPupil/w'),
        );
        let cardY = Math.round(
            +Storage.get('pupils/measureCard/leftPupil/y') -
                cardHeight -
                +Storage.get('pupils/measureCard/leftPupil/h') * 2,
        );
        if (document.documentElement.clientWidth < document.documentElement.clientHeight) {
            // Fix for portrait screens
            cardY += Math.round(+Storage.get('pupils/measureCard/leftPupil/h'));
        }

        Storage.set('objects/cardOutline/x', cardX);
        Storage.set('objects/cardOutline/y', cardY);
        Storage.set('objects/cardOutline/w', cardWidth);
        Storage.set('objects/cardOutline/h', cardHeight);
    }

    Canvas.enableDrawLoop();

    const continueBtn = Dom.getById('dock/step-2/continueBtn');
    continueBtn.addEventListener('click', handleContinueBtnClick);

    const step1Btn = Dom.getById('stepper/step-1');
    step1Btn.addEventListener('click', handleStep1BtnClick);

    const step3Btn = Dom.getById('stepper/step-3');
    step3Btn.addEventListener('click', handleStep3BtnClick);
}

function dispose() {
    Canvas.disableDrawLoop();
    Canvas.clear();

    const continueBtn = Dom.getById('dock/step-2/continueBtn');
    continueBtn.removeEventListener('click', handleContinueBtnClick);

    const step1Btn = Dom.getById('stepper/step-1');
    step1Btn.removeEventListener('click', handleStep1BtnClick);

    const step3Btn = Dom.getById('stepper/step-3');
    step3Btn.removeEventListener('click', handleStep3BtnClick);
}

function _handleContinueBtnClick(event) {
    Calc.convertPupilsToLocalCoords('pupils/measureCard', faceBox);
    Calc.convertCardToLocalCoords('objects/cardOutline', faceBox);
    StepsManager.go('stepTakePhotoWithoutAnything', 'card', {});
}

function _handleStep1BtnClick(event) {
    StepsManager.go('stepTakePhotoWithObject', 'card', {});
}

function _handleStep3BtnClick() {
    const continueBtn = Dom.getById('dock/step-2/continueBtn');
    continueBtn.click();
}

function _drawLoop(ctx) {
    Canvas.clear();

    ctx.drawImage(
        Storage.get('userPhoto/' + this.props.flowType).imageObject,
        faceBox.cropped.x,
        faceBox.cropped.y,
        faceBox.cropped.w,
        faceBox.cropped.h,
    );
    Canvas.drawDebugOutline('red', faceBox.face);
    Canvas.drawDebugOutline('blue', faceBox.newFace);
    Canvas.drawDebugOutline('green', {
        x: 0,
        y: 0,
        w: faceBox.offset.x,
        h: faceBox.offset.y,
    });
    Canvas.drawDebugOutline('orange', faceBox.cropped);
    Canvas.drawPupils('pupils/measureCard');
    Canvas.drawCardOutline('objects/cardOutline');

    Transformable.drawLoop(ctx);
}

function handleMousedownEvent(event) {
    transformableListeners.mousedown(event);
}

function handleMousemoveEvent(event) {
    transformableListeners.mousemove(event);
}

function handleMouseupEvent(event) {
    transformableListeners.mouseup(event);
}

function handleMouseleaveEvent(event) {
    transformableListeners.mouseleave(event);
}

export default [callback, dispose];
