import Storage from './storage';
import Logger from './logger';

class Calc {
    constructor() {}

    clamp(num, min, max) {
        return Math.min(Math.max(num, min), max);
    }

    pointRectCollision(px, py, rx, ry, rw, rh) {
        return px >= rx && px <= rx + rw && py >= ry && py <= ry + rh;
    }

    fitRectInScreen(screenW, screenH, rectW, rectH, contain = false) {
        let rectAR = rectW / rectH;
        let screenAR = screenW / screenH;
        let width = screenW;
        let height = screenH;

        if (contain ? rectAR > screenAR : rectAR < screenAR) {
            height = width / rectAR;
        } else {
            width = height * rectAR;
        }

        return {
            width,
            height,
            x: (screenW - width) / 2,
            y: (screenH - height) / 2,
        };
    }

    distBetweenTwoPoints(x1, y1, x2, y2) {
        const dx = x2 - x1;
        const dy = y2 - y1;

        return Math.round(Math.sqrt(dx * dx + dy * dy));
    }

    angleBetweenTwoPoints(ax1, ay1, ax2, ay2, bx1, by1, bx2, by2) {
        const distAx = ax2 - ax1;
        const distAy = ay2 - ay1;
        const distBx = bx2 - bx1;
        const distBy = by2 - by1;
        const angle = Math.atan2(distAx * distBy - distAy * distBx, distAx * distBx + distAy * distBy);

        return Math.round(angle * (180 / Math.PI));
    }

    cropFaceBox(originalPicture, faceData, canvasWidth, canvasHeight) {
        let faceBox = faceData.faceBox;
        let paddingX = (faceBox.w * 10) / 100;
        let paddingY = (faceBox.h * 10) / 100;

        let original = {
            x: 0,
            y: 0,
            w: originalPicture.width,
            h: originalPicture.height,
        };

        let face = {
            x: faceBox.x - paddingX,
            y: faceBox.y - paddingY,
            w: faceBox.w + paddingX * 2,
            h: faceBox.h + paddingY * 2,
        };

        let ratio = 0;
        if (original.w > original.h) {
            ratio = original.h / face.h;
        } else {
            ratio = original.w / face.w;
        }

        let cropped = {
            x: -1 * face.x * ratio,
            y: -1 * face.h * ratio,
            w: original.w * ratio,
            h: original.h * ratio,
        };

        let newFace = {
            x: face.x * ratio + cropped.x,
            y: face.y * ratio + cropped.y,
            w: face.w * ratio,
            h: face.h * ratio,
        };

        let offset = {
            x: original.w / 2 - newFace.w / 2,
            y: original.h / 2 - newFace.h / 2 - newFace.y,
        };

        cropped.x += offset.x;
        cropped.y += offset.y;

        newFace.x += offset.x;
        newFace.y += offset.y;

        Logger.debug('cropFaceBox');
        Logger.debug('Original:', original);
        Logger.debug('Face:', face);
        Logger.debug('Cropped:', cropped);
        Logger.debug('Ratio:', ratio);
        Logger.debug('Offset:', offset);
        Logger.debug('New face:', newFace);

        return {
            original,
            face,
            cropped,
            ratio,
            offset,
            newFace,
        };
    }

    calculateGlassesDimensions({
        cardPupils,
        glassesPupils,
        adjustPupils,
        glassesWidthMm,
        userGlassesWidthMm,
        cardOutlineWidth,
        glassesOutlineWidth,
        flowType,
    } = {}) {
        Logger.debug('> calculateGlassesDimensions');
        const CARD_WIDTH_MM = 85;

        let firstPupils, glassesWidthPx, pixelsPerMm;
        let secondPupils = adjustPupils;
        Logger.debug('Flow type is:', flowType);
        if (flowType === 'card') {
            firstPupils = cardPupils;
        } else if (flowType === 'glass') {
            firstPupils = glassesPupils;
        }

        // Calculate pupils distances
        let firstPupilsDist = this.distBetweenTwoPoints(
            firstPupils.leftPupil.x,
            firstPupils.leftPupil.y,
            firstPupils.rightPupil.x,
            firstPupils.rightPupil.y,
        );
        let secondPupilsDist = this.distBetweenTwoPoints(
            secondPupils.leftPupil.x,
            secondPupils.leftPupil.y,
            secondPupils.rightPupil.x,
            secondPupils.rightPupil.y,
        );

        // Angle between pupils lines
        let pupilsAngle = this.angleBetweenTwoPoints(
            firstPupils.leftPupil.x,
            firstPupils.leftPupil.y,
            firstPupils.rightPupil.x,
            firstPupils.rightPupil.y,
            secondPupils.leftPupil.x,
            secondPupils.leftPupil.y,
            secondPupils.rightPupil.x,
            secondPupils.rightPupil.y,
        );

        Logger.debug('Distance between', flowType, 'pupils:', firstPupilsDist);
        Logger.debug('Distance between adjustment pupils:', secondPupilsDist);
        Logger.debug('Angle between pupils lines:', pupilsAngle);

        if (flowType === 'card') {
            Logger.debug('Card outline width:', Math.floor(cardOutlineWidth));
            Logger.debug('Card width mm:', CARD_WIDTH_MM);
            // Round down outline width to reduce calculation error
            pixelsPerMm = Math.floor(cardOutlineWidth) / CARD_WIDTH_MM;
        } else if (flowType === 'glass') {
            Logger.debug('Glasses outline width:', Math.floor(glassesOutlineWidth));
            Logger.debug('Glasses width mm:', userGlassesWidthMm);
            // Round down outline width to reduce calculation error
            pixelsPerMm = Math.floor(glassesOutlineWidth) / userGlassesWidthMm;
        }

        Logger.debug('Pixels per mm:', pixelsPerMm);

        // Round down unscaled glasses width to compensate pupils distance
        glassesWidthPx = Math.floor(glassesWidthMm * pixelsPerMm);
        Logger.debug('Unscaled glasses width:', glassesWidthPx);
        // Round down scaled glasses to draw crisp and sharp image
        glassesWidthPx = Math.floor((glassesWidthPx * secondPupilsDist) / firstPupilsDist);
        Logger.debug('Final glasses width:', glassesWidthPx);
        Logger.debug('< calculateGlassesDimensions');

        return {
            w: glassesWidthPx,
        };
    }

    calculatePupilsDist(objectName) {
        return this.distBetweenTwoPoints(
            +Storage.get(objectName + '/leftPupil/x'),
            +Storage.get(objectName + '/leftPupil/y'),
            +Storage.get(objectName + '/rightPupil/x'),
            +Storage.get(objectName + '/rightPupil/y'),
        );
    }

    convertPupilsToLocalCoords(objectName, faceBox) {
        Logger.debug('Pupils to LOCAL coords');

        function localPointLocation(oldX, oldY) {
            const x = Math.round((oldX - faceBox.cropped.x) / faceBox.ratio);
            const y = Math.round((oldY - faceBox.cropped.y) / faceBox.ratio);

            return { x, y };
        }

        let leftPupil = {
            x: +Storage.get(objectName + '/leftPupil/x'),
            y: +Storage.get(objectName + '/leftPupil/y'),
        };

        let rightPupil = {
            x: +Storage.get(objectName + '/rightPupil/x'),
            y: +Storage.get(objectName + '/rightPupil/y'),
        };

        Logger.debug('Original left pupil', leftPupil);
        Logger.debug('Original right pupil', rightPupil);

        leftPupil = localPointLocation(leftPupil.x, leftPupil.y);
        rightPupil = localPointLocation(rightPupil.x, rightPupil.y);

        Logger.debug('New left pupil', leftPupil);
        Logger.debug('New right pupil', rightPupil);

        Storage.set(objectName + '/leftPupil/x', leftPupil.x);
        Storage.set(objectName + '/leftPupil/y', leftPupil.y);

        Storage.set(objectName + '/rightPupil/x', rightPupil.x);
        Storage.set(objectName + '/rightPupil/y', rightPupil.y);
    }

    convertPupilsToGlobalCoords(objectName, faceBox) {
        Logger.debug('Pupils to GLOBAL coords');

        function globalPointLocation(oldX, oldY) {
            const x = Math.round(oldX * faceBox.ratio + faceBox.cropped.x);
            const y = Math.round(oldY * faceBox.ratio + faceBox.cropped.y);

            return { x, y };
        }

        let leftPupil = {
            x: +Storage.get(objectName + '/leftPupil/x'),
            y: +Storage.get(objectName + '/leftPupil/y'),
        };

        let rightPupil = {
            x: +Storage.get(objectName + '/rightPupil/x'),
            y: +Storage.get(objectName + '/rightPupil/y'),
        };

        Logger.debug('Original left pupil', leftPupil);
        Logger.debug('Original right pupil', rightPupil);

        leftPupil = globalPointLocation(leftPupil.x, leftPupil.y);
        rightPupil = globalPointLocation(rightPupil.x, rightPupil.y);

        Logger.debug('New left pupil', leftPupil);
        Logger.debug('New right pupil', rightPupil);

        Storage.set(objectName + '/leftPupil/x', leftPupil.x);
        Storage.set(objectName + '/leftPupil/y', leftPupil.y);

        Storage.set(objectName + '/rightPupil/x', rightPupil.x);
        Storage.set(objectName + '/rightPupil/y', rightPupil.y);
    }

    convertCardToLocalCoords(objectName, faceBox) {
        Logger.debug('Card to LOCAL coords');

        let card = {
            x: +Storage.get(objectName + '/x'),
            y: +Storage.get(objectName + '/y'),
            w: +Storage.get(objectName + '/w'),
            h: +Storage.get(objectName + '/h'),
        };

        Logger.debug('Original card', card);

        card.x = Math.round((card.x - faceBox.cropped.x) / faceBox.ratio);
        card.y = Math.round((card.y - faceBox.cropped.y) / faceBox.ratio);
        card.w = Math.round(card.w / faceBox.ratio);
        card.h = Math.round(card.h / faceBox.ratio);

        Logger.debug('New card', card);

        Storage.set(objectName + '/x', card.x);
        Storage.set(objectName + '/y', card.y);
        Storage.set(objectName + '/w', card.w);
        Storage.set(objectName + '/h', card.h);
    }

    convertCardToGlobalCoords(objectName, faceBox) {
        Logger.debug('Card to GLOBAL coords');

        let card = {
            x: +Storage.get(objectName + '/x'),
            y: +Storage.get(objectName + '/y'),
            w: +Storage.get(objectName + '/w'),
            h: +Storage.get(objectName + '/h'),
        };

        Logger.debug('Original card', card);

        card.x = Math.round(card.x * faceBox.ratio + faceBox.cropped.x);
        card.y = Math.round(card.y * faceBox.ratio + faceBox.cropped.y);
        card.w = Math.round(card.w * faceBox.ratio);
        card.h = Math.round(card.h * faceBox.ratio);

        Logger.debug('New card', card);

        Storage.set(objectName + '/x', card.x);
        Storage.set(objectName + '/y', card.y);
        Storage.set(objectName + '/w', card.w);
        Storage.set(objectName + '/h', card.h);
    }

    convertGlassesToLocalCoords(objectName, faceBox) {
        Logger.debug('Glasses to LOCAL coords');

        let glasses = {
            x: +Storage.get(objectName + '/x'),
            y: +Storage.get(objectName + '/y'),
            w: +Storage.get(objectName + '/w'),
            h: +Storage.get(objectName + '/h'),
        };

        Logger.debug('Original glasses', glasses);

        glasses.x = Math.round((glasses.x - faceBox.cropped.x) / faceBox.ratio);
        glasses.y = Math.round((glasses.y - faceBox.cropped.y) / faceBox.ratio);
        glasses.w = Math.round(glasses.w / faceBox.ratio);
        glasses.h = Math.round(glasses.h / faceBox.ratio);

        Logger.debug('New glasses', glasses);

        Storage.set(objectName + '/x', glasses.x);
        Storage.set(objectName + '/y', glasses.y);
        Storage.set(objectName + '/w', glasses.w);
        Storage.set(objectName + '/h', glasses.h);
    }

    convertGlassesToGlobalCoords(objectName, faceBox) {
        Logger.debug('Glasses to GLOBAL coords');

        let glasses = {
            x: +Storage.get(objectName + '/x'),
            y: +Storage.get(objectName + '/y'),
            w: +Storage.get(objectName + '/w'),
            h: +Storage.get(objectName + '/h'),
        };

        Logger.debug('Original glasses', glasses);

        glasses.x = Math.round(glasses.x * faceBox.ratio + faceBox.cropped.x);
        glasses.y = Math.round(glasses.y * faceBox.ratio + faceBox.cropped.y);
        glasses.w = Math.round(glasses.w * faceBox.ratio);
        glasses.h = Math.round(glasses.h * faceBox.ratio);

        Logger.debug('New glasses', glasses);

        Storage.set(objectName + '/x', glasses.x);
        Storage.set(objectName + '/y', glasses.y);
        Storage.set(objectName + '/w', glasses.w);
        Storage.set(objectName + '/h', glasses.h);
    }
}

const calc = new Calc();
export default calc;
