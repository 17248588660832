import Canvas from '../canvas';
import Storage from '../storage';
import Transformable from '../transformable';
import Dom from '../dom';
import StepsManager from '../steps';

let transformableListeners = {};
let handleRetakeBtnClick;
let handleOkBtnClick;
let handleKeydownEvent;
let handleKeyupEvent;
let handleStep1BtnClick;
let handleStep2BtnClick;
let handleStep3BtnClick;
let activeKey = false;
let photoRect;

function callback(props) {
    this.props = props;
    handleRetakeBtnClick = _handleRetakeBtnClick.bind(this);
    handleOkBtnClick = _handleOkBtnClick.bind(this);
    handleKeydownEvent = _handleKeydownEvent.bind(this);
    handleKeyupEvent = _handleKeyupEvent.bind(this);
    handleStep1BtnClick = _handleStep1BtnClick.bind(this);
    handleStep2BtnClick = _handleStep2BtnClick.bind(this);
    handleStep3BtnClick = _handleStep3BtnClick.bind(this);
    transformableListeners = Transformable.getListeners();

    Transformable.enable('objects/glassesPreview');

    Canvas.subscribeToDrawLoop('stepPreviewGlasses', drawLoop);
    Canvas.subscribeToEvent('stepMeasureGlasses', 'mousedown', handleMousedownEvent);
    Canvas.subscribeToEvent('stepMeasureGlasses', 'touchstart', handleMousedownEvent);
    Canvas.subscribeToEvent('stepMeasureGlasses', 'mousemove', handleMousemoveEvent);
    Canvas.subscribeToEvent('stepMeasureGlasses', 'touchmove', handleMousemoveEvent);
    Canvas.subscribeToEvent('stepMeasureGlasses', 'mouseup', handleMouseupEvent);
    Canvas.subscribeToEvent('stepMeasureGlasses', 'touchend', handleMouseupEvent);
    Canvas.subscribeToEvent('stepMeasureGlasses', 'mouseleave', handleMouseleaveEvent);
    Canvas.subscribeToEvent('stepMeasureGlasses', 'touchcancel', handleMouseleaveEvent);
    window.addEventListener('keydown', handleKeydownEvent);
    window.addEventListener('keyup', handleKeyupEvent);

    Canvas.resizeToVideo();
    photoRect = {
        x: 0,
        y: 0,
        width: Canvas.width(),
        height: Canvas.height(),
    };
    if (window.devicePixelRatio > 1) {
        photoRect.width /= window.devicePixelRatio;
        photoRect.height /= window.devicePixelRatio;
    }
    Canvas.enableDrawLoop();

    const retakeBtn = Dom.getById('dock/step-5/retakeBtn');
    retakeBtn.addEventListener('click', handleRetakeBtnClick);

    const okBtn = Dom.getById('dock/step-5/okBtn');
    okBtn.addEventListener('click', handleOkBtnClick);

    if (Storage.get('localStorageReconstructed')) {
        Dom.getById('stepper').classList.add('hidden');
    } else {
        Dom.getById('stepper').classList.remove('hidden');

        const step1Btn = Dom.getById('stepper/step-1');
        step1Btn.addEventListener('click', handleStep1BtnClick);

        const step2BtnCard = Dom.getById('stepper/step-2-card');
        step2BtnCard.addEventListener('click', handleStep2BtnClick);

        const step2BtnGlass = Dom.getById('stepper/step-2-glass');
        step2BtnGlass.addEventListener('click', handleStep2BtnClick);

        const step3Btn = Dom.getById('stepper/step-3');
        step3Btn.addEventListener('click', handleStep3BtnClick);
    }
}

function dispose() {
    Canvas.disableDrawLoop();
    Canvas.clear();

    const retakeBtn = Dom.getById('dock/step-5/retakeBtn');
    retakeBtn.removeEventListener('click', handleRetakeBtnClick);

    const okBtn = Dom.getById('dock/step-5/okBtn');
    okBtn.removeEventListener('click', handleOkBtnClick);

    const step1Btn = Dom.getById('stepper/step-1');
    step1Btn.removeEventListener('click', handleStep1BtnClick);

    const step2BtnCard = Dom.getById('stepper/step-2-card');
    step2BtnCard.removeEventListener('click', handleStep2BtnClick);

    const step2BtnGlass = Dom.getById('stepper/step-2-glass');
    step2BtnGlass.removeEventListener('click', handleStep2BtnClick);

    const step3Btn = Dom.getById('stepper/step-3');
    step3Btn.removeEventListener('click', handleStep3BtnClick);

    window.removeEventListener('keydown', handleKeydownEvent);
    window.removeEventListener('keyup', handleKeyupEvent);
}

function _handleRetakeBtnClick(event) {
    Dom.getById('stepper').classList.remove('hidden');
    NMTryon.reset();

    if (this.props.flowType === 'card') {
        StepsManager.go('stepTakePhotoWithObject', 'card', {});
    } else if (this.props.flowType === 'glass') {
        StepsManager.go('stepTakePhotoWithObject', 'glass', {});
    }
}

function _handleOkBtnClick(event) {
    Storage.saveGlassesCalcData();

    document.body.classList.remove('tryon-blockscroll');
    Dom.root().classList.remove('active');
    Dom.introRoot().classList.remove('active');
    Dom.getById('stepper').classList.remove('hidden');
    document.querySelector('meta[name="viewport"]').content = Storage.get('metaViewportBackup');
}

function _handleStep1BtnClick() {
    Storage.set('finished', false);
    StepsManager.go('stepTakePhotoWithObject', this.props.flowType, {});
}

function _handleStep2BtnClick() {
    Storage.set('finished', false);
    if (this.props.flowType == 'card') {
        StepsManager.go('stepMeasureCard', 'card', { keepCardPos: true });
    } else if (this.props.flowType == 'glass') {
        StepsManager.go('stepMeasureGlasses', 'glass', { keepGlassesPos: true });
    }
}

function _handleStep3BtnClick(event) {
    Storage.set('finished', false);
    StepsManager.go('stepTakePhotoWithoutAnything', this.props.flowType, {});
}

function drawLoop(ctx) {
    Canvas.clear();

    if (activeKey === 'top') {
        Storage.set('objects/glassesPreview/y', +Storage.get('objects/glassesPreview/y') - 1);
    } else if (activeKey === 'left') {
        Storage.set('objects/glassesPreview/x', +Storage.get('objects/glassesPreview/x') - 1);
    } else if (activeKey === 'bottom') {
        Storage.set('objects/glassesPreview/y', +Storage.get('objects/glassesPreview/y') + 1);
    } else if (activeKey === 'right') {
        Storage.set('objects/glassesPreview/x', +Storage.get('objects/glassesPreview/x') + 1);
    }

    ctx.drawImage(
        Storage.get('userPhoto/withoutAnything').imageObject,
        photoRect.x,
        photoRect.y,
        photoRect.width,
        photoRect.height,
    );
    Canvas.drawPreviewGlasses('objects/glassesPreview');

    Transformable.drawLoop(ctx);
}

function handleMousedownEvent(event) {
    transformableListeners.mousedown(event);
}

function handleMousemoveEvent(event) {
    transformableListeners.mousemove(event);
}

function handleMouseupEvent(event) {
    transformableListeners.mouseup(event);
}

function handleMouseleaveEvent(event) {
    transformableListeners.mouseleave(event);
}

function _handleKeydownEvent(event) {
    if (event.code == 'ArrowUp') activeKey = 'top';
    else if (event.code == 'ArrowLeft') activeKey = 'left';
    else if (event.code == 'ArrowDown') activeKey = 'bottom';
    else if (event.code == 'ArrowRight') activeKey = 'right';
}

function _handleKeyupEvent(event) {
    activeKey = false;
}

export default [callback, dispose];
