import Storage from './storage';
import Dom from './dom';
import Logger from './logger';
import Calc from './calc';

async function canvasToBytes(canvas) {
    return new Promise((resolve, reject) => {
        try {
            canvas.toBlob(
                (blob) => {
                    blob.arrayBuffer().then((bytes) => {
                        resolve(bytes);
                    });
                },
                'image/jpeg',
                1,
            );
        } catch (error) {
            reject(error);
        }
    });
}

async function loadImage(url = '') {
    return new Promise((resolve, reject) => {
        const image = new Image();
        image.crossOrigin = 'anonymous';
        image.addEventListener('load', () => resolve(image));
        image.addEventListener('error', (error) => reject(error));
        image.src = url;
    });
}

function isIOS() {
    const userAgent = navigator.userAgent.toLowerCase();

    return (
        ['iPad Simulator', 'iPhone Simulator', 'iPod Simulator', 'iPad', 'iPhone', 'iPod'].includes(
            navigator.platform,
        ) ||
        (userAgent.indexOf('mac') > -1 && 'ontouchend' in document)
    );
}

class Camera {
    #stream;

    constructor() {
        this.#stream = false;
    }

    async getStream(createIfNotExists = true) {
        const video = Dom.getById('main-video');

        let stream = Storage.get('camera/stream');
        if (!stream && createIfNotExists) {
            Logger.debug('Camera stream was not found, requesting a new one...');
            let constraints = {
                audio: false,
                video: {},
            };

            const supportedConstraints = await navigator.mediaDevices.getSupportedConstraints();
            Logger.debug('Supported constraints:', supportedConstraints);

            if (supportedConstraints.facingMode) {
                constraints.video.facingMode = 'user';
            }

            if (supportedConstraints.frameRate) {
                constraints.video.frameRate = {
                    min: 15,
                    ideal: 30,
                    max: 60,
                };
            }

            if (supportedConstraints.width && supportedConstraints.height) {
                constraints.video.width = {};
                constraints.video.width.min = 480;
                constraints.video.width.max = 1920;

                constraints.video.height = {};
                constraints.video.height.min = 480;
                constraints.video.height.max = 1920;

                if (document.documentElement.clientWidth > document.documentElement.clientHeight) {
                    Logger.debug('Screen is landscape');
                    constraints.video.width.ideal = 1920;
                    constraints.video.height.ideal = 1080;
                } else {
                    Logger.debug('Screen is portrait');

                    if (isIOS()) {
                        constraints.video.width.ideal = window.screen.width;
                        constraints.video.height.ideal = window.screen.height;

                        if (video) {
                            video.style.objectFit = 'cover';
                        }
                    } else {
                        constraints.video.width.ideal = 1080;
                        constraints.video.height.ideal = 1920;

                        if (video) {
                            video.style.objectFit = null;
                        }
                    }
                }

                Logger.debug('Request video width', constraints.video.width.ideal);
                Logger.debug('Request video height', constraints.video.height.ideal);
            }

            stream = await navigator.mediaDevices.getUserMedia(constraints);

            Logger.debug('Video stream', stream);
            stream.getTracks().forEach(function (track) {
                Logger.debug('Track', track.getSettings());
            });
            Storage.set('camera/stream', stream);
        }

        this.#stream = stream;
        return stream;
    }

    async closeStream() {
        const stream = await this.getStream(false);
        if (stream) {
            stream.getTracks().forEach((track) => {
                if (track.readyState == 'live') {
                    track.stop();
                }
            });
            Dom.getById('main-video').pause();
            Dom.getById('main-video').srcObject = null;
            Storage.remove('camera/stream');
            this.#stream = false;
        }
    }

    async play(createIfNotExists = true) {
        const video = Dom.getById('main-video');
        if (video) {
            if (video.srcObject != null) {
                video.play();
            } else {
                const stream = await this.getStream(createIfNotExists);
                if (stream) {
                    video.srcObject = stream;
                    video.play();
                }
            }
        }
    }

    pause() {
        if (Dom.getById('main-video')) {
            Dom.getById('main-video').pause();
        }
    }

    async takePhoto() {
        Logger.debug('> takePhoto');
        await this.pause();

        const video = Dom.getById('main-video');
        const tmpCanvas = document.createElement('canvas');
        const tmpCtx = tmpCanvas.getContext('2d');
        let rect;
        if (!isIOS()) {
            Logger.debug('Emulating object-fit: contain');
            // On normal devices we use 'contain' mode
            rect = Calc.fitRectInScreen(
                video.clientWidth,
                video.clientHeight,
                video.videoWidth,
                video.videoHeight,
                true,
            );
        } else {
            Logger.debug('Emulating object-fit: cover');
            // On iOS devices we use 'cover' mode
            rect = Calc.fitRectInScreen(
                video.clientWidth,
                video.clientHeight,
                video.videoWidth,
                video.videoHeight,
                false,
            );
        }
        Logger.debug('Photo rectangle:', rect);

        tmpCanvas.width = video.clientWidth;
        tmpCanvas.height = video.clientHeight;
        tmpCtx.clearRect(0, 0, video.clientWidth, video.clientHeight);
        tmpCtx.scale(-1, 1);
        tmpCtx.drawImage(video, -rect.x, rect.y, -rect.width, rect.height);
        tmpCtx.setTransform(1, 0, 0, 1, 0, 0);

        await this.play();

        const dataUrl = await tmpCanvas.toDataURL('image/png', 0.9);
        const imageBytes = await canvasToBytes(tmpCanvas);
        const imageObject = await loadImage(dataUrl);

        tmpCanvas.remove();
        Logger.debug('< takePhoto');

        return {
            dataUrl,
            imageBytes,
            imageObject,
            width: tmpCanvas.width,
            height: tmpCanvas.height,
        };
    }
}

const camera = new Camera();
export default camera;
