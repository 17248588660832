import Camera from '../camera';
import Dom from '../dom';
import Logger from '../logger';
import AWS from '../aws';
import Loader from '../loader';
import Storage from '../storage';
import StepsManager from '../steps';
import Calc from '../calc';

let handleCameraBtnClick;
let handleStep2BtnClick;

function callback(props) {
    Camera.play(true);
    this.props = props;
    handleCameraBtnClick = _handleCameraBtnClick.bind(this);
    handleStep2BtnClick = _handleStep2BtnClick.bind(this);

    const cameraBtn = Dom.getById('dock/step-1/cameraBtn');
    cameraBtn.addEventListener('click', handleCameraBtnClick);

    const step2BtnCard = Dom.getById('stepper/step-2-card');
    step2BtnCard.addEventListener('click', handleStep2BtnClick);

    const step2BtnGlass = Dom.getById('stepper/step-2-glass');
    step2BtnGlass.addEventListener('click', handleStep2BtnClick);
}

function dispose(keepCamera = false) {
    const cameraBtn = Dom.getById('dock/step-1/cameraBtn');
    cameraBtn.removeEventListener('click', handleCameraBtnClick);
    if (!keepCamera) {
        Camera.pause();
        Camera.closeStream();
    }

    const step2BtnCard = Dom.getById('stepper/step-2-card');
    step2BtnCard.removeEventListener('click', handleStep2BtnClick);

    const step2BtnGlass = Dom.getById('stepper/step-2-glass');
    step2BtnGlass.removeEventListener('click', handleStep2BtnClick);
}

function _handleCameraBtnClick(event) {
    Camera.pause();
    Loader.show('Taking photo...', true);
    Camera.takePhoto().then((photo) => {
        Camera.pause();
        Storage.set('userPhoto/' + this.props.flowType, photo);

        Logger.debug('Camera photo data', photo);
        Loader.updateText('Analyzing image...');

        AWS.detectFaces(photo.dataUrl.replace(/^data:image\/(png|jpeg|jpg);base64,/, ''), photo.width, photo.height)
            .then((faces) => {
                Storage.set('faces/photoWithObject', faces);

                let faceData = faces[0];

                let objectName;
                if (this.props.flowType === 'card') {
                    objectName = 'measureCard';
                } else if (this.props.flowType === 'glass') {
                    objectName = 'measureGlasses';
                }

                Storage.set('pupils/' + objectName + '/leftPupil/x', faceData.leftPupil.x);
                Storage.set('pupils/' + objectName + '/leftPupil/y', faceData.leftPupil.y);

                Storage.set('pupils/' + objectName + '/rightPupil/x', faceData.rightPupil.x);
                Storage.set('pupils/' + objectName + '/rightPupil/y', faceData.rightPupil.y);

                Loader.updateText('Done!');
                Loader.hide();

                Camera.closeStream();

                if (this.props.flowType === 'card') {
                    StepsManager.go('stepMeasureCard', 'card', {});
                } else if (this.props.flowType === 'glass') {
                    StepsManager.go('stepMeasureGlasses', 'glass', {});
                }
            })
            .catch((error) => {
                Camera.closeStream();
                Loader.setErrorMode('Try again', () => {
                    Loader.hide();
                    Loader.setLoadingMode();
                    StepsManager.go('stepTakePhotoWithObject', this.props.flowType, {});
                });
                Loader.updateText(
                    'We didnt recognize the pupils on your photo.\nPlease retake photo',
                    'Something went wrong...',
                );
            });
    });
}

function _handleStep2BtnClick() {
    const cameraBtn = Dom.getById('dock/step-1/cameraBtn');
    cameraBtn.click();
}

export default [callback, dispose];
