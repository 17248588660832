import Canvas from '../canvas';
import Storage from '../storage';
import Transformable from '../transformable';
import Dom from '../dom';
import StepsManager from '../steps';
import Logger from '../logger';
import Calc from '../calc';

let transformableListeners = {};
let handleContinueBtnClick;
let handleStep1BtnClick;
let handleStep3BtnClick;
let drawLoop;
let faceBox;

function callback(props) {
    this.props = props;
    handleContinueBtnClick = _handleContinueBtnClick.bind(this);
    handleStep1BtnClick = _handleStep1BtnClick.bind(this);
    handleStep3BtnClick = _handleStep3BtnClick.bind(this);
    drawLoop = _drawLoop.bind(this);
    transformableListeners = Transformable.getListeners();

    Transformable.enable('pupils/measureGlasses/leftPupil');
    Transformable.enable('pupils/measureGlasses/rightPupil');
    Transformable.enable('objects/glassesOutline');

    Canvas.resizeToVideo();

    Canvas.subscribeToDrawLoop('stepMeasureGlasses', drawLoop);
    Canvas.subscribeToEvent('stepMeasureGlasses', 'mousedown', handleMousedownEvent);
    Canvas.subscribeToEvent('stepMeasureGlasses', 'touchstart', handleMousedownEvent);
    Canvas.subscribeToEvent('stepMeasureGlasses', 'mousemove', handleMousemoveEvent);
    Canvas.subscribeToEvent('stepMeasureGlasses', 'touchmove', handleMousemoveEvent);
    Canvas.subscribeToEvent('stepMeasureGlasses', 'mouseup', handleMouseupEvent);
    Canvas.subscribeToEvent('stepMeasureGlasses', 'touchend', handleMouseupEvent);
    Canvas.subscribeToEvent('stepMeasureGlasses', 'mouseleave', handleMouseleaveEvent);
    Canvas.subscribeToEvent('stepMeasureGlasses', 'touchcancel', handleMouseleaveEvent);

    faceBox = Calc.cropFaceBox(
        Storage.get('userPhoto/' + this.props.flowType),
        Storage.get('faces/photoWithObject')[0],
        Canvas.width(),
        Canvas.height(),
    );
    Calc.convertPupilsToGlobalCoords('pupils/measureGlasses', faceBox);

    if (this.props.keepGlassesPos) {
        Calc.convertGlassesToGlobalCoords('objects/glassesOutline', faceBox);
    } else {
        let canvasWidth = Canvas.width();
        if (window.devicePixelRatio > 1) {
            canvasWidth /= window.devicePixelRatio;
        }

        let glassesWidth = Math.round(
            Calc.calculatePupilsDist('pupils/measureGlasses') + +Storage.get('pupils/measureGlasses/leftPupil/w') * 4,
        );
        let glassesHeight = 74;
        let glassesX = Math.round(
            +Storage.get('pupils/measureGlasses/leftPupil/x') - +Storage.get('pupils/measureGlasses/leftPupil/w') * 2,
        );
        let glassesY = Math.round(
            +Storage.get('pupils/measureGlasses/leftPupil/y') - +Storage.get('pupils/measureGlasses/leftPupil/h') * 1.5,
        );
        if (document.documentElement.clientWidth < document.documentElement.clientHeight) {
            // Fix for portrait screens
            glassesWidth = Math.round(
                Calc.calculatePupilsDist('pupils/measureGlasses') +
                    +Storage.get('pupils/measureGlasses/leftPupil/w') * 2,
            );
            glassesX = Math.round(
                +Storage.get('pupils/measureGlasses/leftPupil/x') - +Storage.get('pupils/measureGlasses/leftPupil/w'),
            );
        }

        Storage.set('objects/glassesOutline/x', glassesX);
        Storage.set('objects/glassesOutline/y', glassesY);
        Storage.set('objects/glassesOutline/w', glassesWidth);
        Storage.set('objects/glassesOutline/h', glassesHeight);
    }

    Canvas.enableDrawLoop();

    const continueBtn = Dom.getById('dock/step-2/continueBtn');
    continueBtn.addEventListener('click', handleContinueBtnClick);

    const step1Btn = Dom.getById('stepper/step-1');
    step1Btn.addEventListener('click', handleStep1BtnClick);

    const step3Btn = Dom.getById('stepper/step-3');
    step3Btn.addEventListener('click', handleStep3BtnClick);
}

function dispose() {
    Canvas.disableDrawLoop();
    Canvas.clear();

    const continueBtn = Dom.getById('dock/step-2/continueBtn');
    continueBtn.removeEventListener('click', handleContinueBtnClick);

    const step1Btn = Dom.getById('stepper/step-1');
    step1Btn.removeEventListener('click', handleStep1BtnClick);

    const step3Btn = Dom.getById('stepper/step-3');
    step3Btn.removeEventListener('click', handleStep3BtnClick);
}

function _handleContinueBtnClick(event) {
    let widthMm = 0;
    const widthForm = Dom.getById('dock/step-2/form');
    const widthInput = Dom.getById('dock/step-2/widthInput');
    const unitSelect = Dom.getById('dock/step-2/unitSelect');
    const widthUnit = unitSelect.value;

    if (widthUnit == 'mm') {
        widthMm = +widthInput.value;
    } else if (widthUnit == 'in') {
        // Convert inches to millimeters
        widthMm = Math.ceil(+widthInput.value * 25.4);
    }

    Logger.debug('Glasses size:', +widthInput.value, widthUnit);
    Logger.debug('Glasses size converted:', widthMm, 'mm');

    widthForm.classList.remove('invalid');
    if (widthMm < 100 || widthMm > 200) {
        Logger.debug('Glasses size is invalid!');
        widthForm.classList.add('invalid');
        return;
    }

    Storage.set('userData/glassesWidthMm', widthMm);
    Calc.convertPupilsToLocalCoords('pupils/measureGlasses', faceBox);
    Calc.convertGlassesToLocalCoords('objects/glassesOutline', faceBox);
    StepsManager.go('stepTakePhotoWithoutAnything', 'glass', {});
}

function _handleStep1BtnClick(event) {
    StepsManager.go('stepTakePhotoWithObject', 'glass', {});
}

function _handleStep3BtnClick() {
    const continueBtn = Dom.getById('dock/step-2/continueBtn');
    continueBtn.click();
}

function _drawLoop(ctx) {
    Canvas.clear();

    ctx.drawImage(
        Storage.get('userPhoto/' + this.props.flowType).imageObject,
        faceBox.cropped.x,
        faceBox.cropped.y,
        faceBox.cropped.w,
        faceBox.cropped.h,
    );
    Canvas.drawDebugOutline('red', faceBox.face);
    Canvas.drawDebugOutline('blue', faceBox.newFace);
    Canvas.drawDebugOutline('green', {
        x: 0,
        y: 0,
        w: faceBox.offset.x,
        h: faceBox.offset.y,
    });
    Canvas.drawDebugOutline('orange', faceBox.cropped);
    Canvas.drawPupils('pupils/measureGlasses');
    Canvas.drawGlassesOutline('objects/glassesOutline');

    Transformable.drawLoop(ctx);
}

function handleMousedownEvent(event) {
    transformableListeners.mousedown(event);
}

function handleMousemoveEvent(event) {
    transformableListeners.mousemove(event);
}

function handleMouseupEvent(event) {
    transformableListeners.mouseup(event);
}

function handleMouseleaveEvent(event) {
    transformableListeners.mouseleave(event);
}

export default [callback, dispose];
