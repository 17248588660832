import Dom from './dom';

class Loader {
    #loader;
    #loaderTitle;
    #loaderDescription;
    #loaderActionBtn;
    #visible;
    #actionBtnHandler;

    constructor() {
        this.#loader = false;
        this.#loaderTitle = false;
        this.#loaderDescription = false;
        this.#loaderActionBtn = false;
        this.#visible = false;
    }

    init() {
        this.#loader = Dom.getById('main-loader');
        this.#loaderTitle = Dom.getById('main-loader/title');
        this.#loaderDescription = Dom.getById('main-loader/description');
        this.#loaderActionBtn = Dom.getById('main-loader/actionBtn');
    }

    show(description = '', hideUi = false, title = '') {
        this.#visible = true;
        this.#loader.classList.add('active');
        if (title.length > 0) this.#loaderTitle.textContent = title;
        if (description.length > 0) this.#loaderDescription.textContent = description;
        if (hideUi) {
            Dom.getById('stepper').classList.add('hidden');
            Dom.getById('dock').classList.add('hidden');
        }
    }

    hide() {
        this.#visible = false;
        this.#loader.classList.remove('active');
        Dom.getById('stepper').classList.remove('hidden');
        Dom.getById('dock').classList.remove('hidden');
    }

    toggle() {
        if (this.#visible) {
            this.hide();
        } else {
            this.show();
        }
    }

    updateText(description = '', title = '') {
        if (title.length > 0) this.#loaderTitle.textContent = title;
        if (description.length > 0) this.#loaderDescription.textContent = description;
    }

    setLoadingMode() {
        this.#loader.classList.remove('loader--error_mode');
        this.#loaderTitle.textContent = 'Please wait...';
        this.#loaderActionBtn.removeEventListener('click', this.#actionBtnHandler);
    }

    setErrorMode(actionBtnTitle = 'Action', actionBtnHandler = false) {
        this.#actionBtnHandler = actionBtnHandler.bind(this);
        this.#loader.classList.add('loader--error_mode');
        this.#loaderActionBtn.textContent = actionBtnTitle;
        this.#loaderActionBtn.addEventListener('click', this.#actionBtnHandler);
    }
}

const loader = new Loader();
export default loader;
