import QRCode from 'qrcode';

class _QRCode {
    constructor() {}

    async toDataUrl(text, size = 400, fgColor = '#222222', bgColor = '#ffffff') {
        const params = {
            type: 'svg',
            quality: 1,
            margin: 0,
            width: size,
            color: {
                light: bgColor,
                dark: fgColor,
            },
            errorCorrectionLevel: 'L',
        };

        return new Promise((resolve, reject) => {
            QRCode.toString(text, params, function (error, url) {
                if (error) reject(error);

                resolve('data:image/svg+xml;base64,' + window.btoa(url));
            });
        });
    }
}

const qrcode = new _QRCode();
export default qrcode;
