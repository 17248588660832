class Dom {
    #root;
    #introRoot;
    #cache;
    #cacheIntro;

    constructor() {
        this.#root = false;
        this.#cache = {};
        this.#cacheIntro = {};
    }

    init() {
        this.#root = document.querySelector('#tryon');
        this.#introRoot = document.querySelector('#tryon-popup');
    }

    root() {
        return this.#root;
    }

    introRoot() {
        return this.#introRoot;
    }

    getById(id, invalidateCache = false) {
        if (!this.#root) return false;
        let element = this.#cache[id];
        if (!element || invalidateCache) {
            element = this.#root.querySelector(`[data-dom-id="${id}"]`);
            this.#cache[id] = element;
        }

        return element || false;
    }

    getBySelector(selector, invalidateCache = false) {
        if (!this.#root) return false;
        let element = this.#cache['selector_' + selector];
        if (!element || invalidateCache) {
            element = this.#root.querySelector(selector);
            this.#cache['selector_' + selector] = element;
        }

        return element || false;
    }

    getByIdFromIntro(id, invalidateCache = false) {
        if (!this.#introRoot) return false;
        let element = this.#cacheIntro[id];
        if (!element || invalidateCache) {
            element = this.#introRoot.querySelector(`[data-dom-id="${id}"]`);
            this.#cacheIntro[id] = element;
        }

        return element || false;
    }

    getBySelectorFromIntro(selector, invalidateCache = false) {
        if (!this.#introRoot) return false;
        let element = this.#cache['selector_' + selector];
        if (!element || invalidateCache) {
            element = this.#introRoot.querySelector(selector);
            this.#cache['selector_' + selector] = element;
        }

        return element || false;
    }

    getAll(id, invalidateCache = false) {
        if (!this.#root) return false;
        let elements = this.#cache[id + '_all'];
        if (!elements || invalidateCache) {
            elements = this.#root.querySelectorAll(`[data-dom="${id}"]`);
            this.#cache[id + '_all'] = Array.from(elements);
        }

        return elements || [];
    }

    getAllFromIntro(id, invalidateCache = false) {
        if (!this.#introRoot) return false;
        let elements = this.#cacheIntro[id + '_all'];
        if (!elements || invalidateCache) {
            elements = this.#introRoot.querySelectorAll(`[data-dom="${id}"]`);
            this.#cacheIntro[id + '_all'] = Array.from(elements);
        }

        return elements || [];
    }
}

const dom = new Dom();
export default dom;
