import Logger from './logger';

const localStoragePrefix = 'nmto-v2_';
const pupilSize = 60;

const defaultStorage = {
    'const/cardWidthMm': 85,
    'const/pupilSize': pupilSize,
    'pupils/measureCard/leftPupil/w': pupilSize,
    'pupils/measureCard/leftPupil/h': pupilSize,
    'pupils/measureCard/rightPupil/w': pupilSize,
    'pupils/measureCard/rightPupil/h': pupilSize,
    'pupils/measureGlasses/leftPupil/w': pupilSize,
    'pupils/measureGlasses/leftPupil/h': pupilSize,
    'pupils/measureGlasses/rightPupil/w': pupilSize,
    'pupils/measureGlasses/rightPupil/h': pupilSize,
    'pupils/adjustPupils/leftPupil/w': pupilSize,
    'pupils/adjustPupils/leftPupil/h': pupilSize,
    'pupils/adjustPupils/rightPupil/w': pupilSize,
    'pupils/adjustPupils/rightPupil/h': pupilSize,
    finished: false,
};

function objectToLocalstorage(key, obj) {
    try {
        window.localStorage.setItem(localStoragePrefix + key, JSON.stringify(obj));
    } catch (error) {
        Logger.debug('Cant save object to local storage', key, '=>', obj);
    }
}

function localstorageToObject(key, defaultValue) {
    try {
        if (window.localStorage.hasOwnProperty(localStoragePrefix + key)) {
            return JSON.parse(window.localStorage.getItem(localStoragePrefix + key));
        }

        return defaultValue;
    } catch (error) {
        Logger.debug('Cant load object from local storage', key);
        return null;
    }
}

class Storage {
    #storage;

    constructor() {
        this.#storage = { ...defaultStorage };
    }

    set(key, value) {
        this.#storage[key] = value;
    }

    get(key) {
        return this.#storage[key];
    }

    remove(key) {
        delete this.#storage[key];
    }

    clearAll() {
        this.#storage = { ...defaultStorage };
    }

    each(callback) {
        Object.keys(this.#storage).forEach((key) => {
            callback(this.#storage[key], key);
        });
    }

    getGlassesCalcData() {
        let data = {
            pupils: {
                measureCard: {
                    leftPupil: {
                        x: +this.get('pupils/measureCard/leftPupil/x'),
                        y: +this.get('pupils/measureCard/leftPupil/y'),
                        w: +this.get('pupils/measureCard/leftPupil/w'),
                        h: +this.get('pupils/measureCard/leftPupil/h'),
                    },
                    rightPupil: {
                        x: +this.get('pupils/measureCard/rightPupil/x'),
                        y: +this.get('pupils/measureCard/rightPupil/y'),
                        w: +this.get('pupils/measureCard/rightPupil/w'),
                        h: +this.get('pupils/measureCard/rightPupil/h'),
                    },
                },
                measureGlasses: {
                    leftPupil: {
                        x: +this.get('pupils/measureGlasses/leftPupil/x'),
                        y: +this.get('pupils/measureGlasses/leftPupil/y'),
                        w: +this.get('pupils/measureGlasses/leftPupil/w'),
                        h: +this.get('pupils/measureGlasses/leftPupil/h'),
                    },
                    rightPupil: {
                        x: +this.get('pupils/measureGlasses/rightPupil/x'),
                        y: +this.get('pupils/measureGlasses/rightPupil/y'),
                        w: +this.get('pupils/measureGlasses/rightPupil/w'),
                        h: +this.get('pupils/measureGlasses/rightPupil/h'),
                    },
                },
                adjustPupils: {
                    leftPupil: {
                        x: +this.get('pupils/adjustPupils/leftPupil/x'),
                        y: +this.get('pupils/adjustPupils/leftPupil/y'),
                        w: +this.get('pupils/adjustPupils/leftPupil/w'),
                        h: +this.get('pupils/adjustPupils/leftPupil/h'),
                    },
                    rightPupil: {
                        x: +this.get('pupils/adjustPupils/rightPupil/x'),
                        y: +this.get('pupils/adjustPupils/rightPupil/y'),
                        w: +this.get('pupils/adjustPupils/rightPupil/w'),
                        h: +this.get('pupils/adjustPupils/rightPupil/h'),
                    },
                },
            },
            glassesWidthMm: +this.get('glassesWidthMm'),
            userData: {
                glassesWidthMm: +this.get('userData/glassesWidthMm'),
            },
            objects: {
                cardOutline: {
                    x: +this.get('objects/cardOutline/x'),
                    y: +this.get('objects/cardOutline/y'),
                    w: +this.get('objects/cardOutline/w'),
                    h: +this.get('objects/cardOutline/h'),
                },
                glassesOutline: {
                    x: +this.get('objects/glassesOutline/x'),
                    y: +this.get('objects/glassesOutline/y'),
                    w: +this.get('objects/glassesOutline/w'),
                    h: +this.get('objects/glassesOutline/h'),
                },
                glassesPreview: {
                    x: +this.get('objects/glassesPreview/x'),
                    y: +this.get('objects/glassesPreview/y'),
                    w: +this.get('objects/glassesPreview/w'),
                    h: +this.get('objects/glassesPreview/h'),
                },
            },
            flowType: this.get('flowType'),
        };

        return data;
    }

    setGlassesCalcData(data) {
        this.set('pupils/measureCard/leftPupil/x', data.pupils.measureCard.leftPupil.x);
        this.set('pupils/measureCard/leftPupil/y', data.pupils.measureCard.leftPupil.y);
        this.set('pupils/measureCard/leftPupil/w', data.pupils.measureCard.leftPupil.w);
        this.set('pupils/measureCard/leftPupil/h', data.pupils.measureCard.leftPupil.h);

        this.set('pupils/measureCard/rightPupil/x', data.pupils.measureCard.rightPupil.x);
        this.set('pupils/measureCard/rightPupil/y', data.pupils.measureCard.rightPupil.y);
        this.set('pupils/measureCard/rightPupil/w', data.pupils.measureCard.rightPupil.w);
        this.set('pupils/measureCard/rightPupil/h', data.pupils.measureCard.rightPupil.h);

        this.set('pupils/measureGlasses/leftPupil/x', data.pupils.measureGlasses.leftPupil.x);
        this.set('pupils/measureGlasses/leftPupil/y', data.pupils.measureGlasses.leftPupil.y);
        this.set('pupils/measureGlasses/leftPupil/w', data.pupils.measureGlasses.leftPupil.w);
        this.set('pupils/measureGlasses/leftPupil/h', data.pupils.measureGlasses.leftPupil.h);

        this.set('pupils/measureGlasses/rightPupil/x', data.pupils.measureGlasses.rightPupil.x);
        this.set('pupils/measureGlasses/rightPupil/y', data.pupils.measureGlasses.rightPupil.y);
        this.set('pupils/measureGlasses/rightPupil/w', data.pupils.measureGlasses.rightPupil.w);
        this.set('pupils/measureGlasses/rightPupil/h', data.pupils.measureGlasses.rightPupil.h);

        this.set('pupils/adjustPupils/leftPupil/x', data.pupils.adjustPupils.leftPupil.x);
        this.set('pupils/adjustPupils/leftPupil/y', data.pupils.adjustPupils.leftPupil.y);
        this.set('pupils/adjustPupils/leftPupil/w', data.pupils.adjustPupils.leftPupil.w);
        this.set('pupils/adjustPupils/leftPupil/h', data.pupils.adjustPupils.leftPupil.h);

        this.set('pupils/adjustPupils/rightPupil/x', data.pupils.adjustPupils.rightPupil.x);
        this.set('pupils/adjustPupils/rightPupil/y', data.pupils.adjustPupils.rightPupil.y);
        this.set('pupils/adjustPupils/rightPupil/w', data.pupils.adjustPupils.rightPupil.w);
        this.set('pupils/adjustPupils/rightPupil/h', data.pupils.adjustPupils.rightPupil.h);

        this.set('glassesWidthMm', data.glassesWidthMm);
        this.set('userData/glassesWidthMm', data.userData.glassesWidthMm);

        this.set('objects/cardOutline/x', data.objects.cardOutline.x);
        this.set('objects/cardOutline/y', data.objects.cardOutline.y);
        this.set('objects/cardOutline/w', data.objects.cardOutline.w);
        this.set('objects/cardOutline/h', data.objects.cardOutline.h);

        this.set('objects/glassesOutline/x', data.objects.glassesOutline.x);
        this.set('objects/glassesOutline/y', data.objects.glassesOutline.y);
        this.set('objects/glassesOutline/w', data.objects.glassesOutline.w);
        this.set('objects/glassesOutline/h', data.objects.glassesOutline.h);

        this.set('objects/glassesPreview/x', data.objects.glassesPreview.x);
        this.set('objects/glassesPreview/y', data.objects.glassesPreview.y);
        this.set('objects/glassesPreview/w', data.objects.glassesPreview.w);
        this.set('objects/glassesPreview/h', data.objects.glassesPreview.h);

        this.set('flowType', data.flowType);
    }

    saveGlassesCalcData() {
        let data = this.getGlassesCalcData();
        Logger.debug('Save glasses calc data to localStorage', data);
        objectToLocalstorage('calc-data', data);
        objectToLocalstorage('calc-photo', this.get('userPhoto/withoutAnything'));
    }

    loadGlassesCalcData() {
        let success = false;
        let data = localstorageToObject('calc-data', false);
        let photo = localstorageToObject('calc-photo', false);

        this.set('localStorageReconstructed', false);

        if (data) {
            this.setGlassesCalcData(data);
            Logger.debug('Load glasses calc data from local storage');
        }

        if (photo && photo.imageObject) {
            this.set('userPhoto/withoutAnything', photo);
            Logger.debug('Load calc photo from local storage');
        }

        if (data && photo && photo.imageObject) {
            this.set('localStorageReconstructed', true);
            success = true;
        }

        return success;
    }

    clearSavedGlassesCalcData() {
        objectToLocalstorage('calc-data', false);
        objectToLocalstorage('calc-photo', false);
    }
}

const storage = new Storage();
export default storage;
