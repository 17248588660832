class Logger {
    constructor() {
        this.debugMode = false;
    }

    #currentTime() {
        return new Date().toTimeString().substring(0, 8);
    }

    toggleDebugMode(value) {
        this.debugMode = !!value;
    }

    debug(...msg) {
        if (this.debugMode) {
            console.debug(`%c[${this.#currentTime()}][DEBUG]`, 'color: yellowgreen;', ...msg);
        }
    }

    warn(...msg) {
        console.warn(`%c[${this.#currentTime()}][WARN]`, 'color: orange;', ...msg);
    }

    error(...msg) {
        console.error(`%c[${this.#currentTime()}][ERROR]`, 'color: orangered;', ...msg);
    }
}

const logger = new Logger();
export default logger;
