import Dom from './dom';
import Logger from './logger';
import Canvas from './canvas';
import Transformable from './transformable';
import Storage from './storage';

class StepsManager {
    #steps;
    #currentStep;

    constructor() {
        this.#steps = {};
        this.#currentStep = false;
    }

    register(id, name, functions) {
        this.#steps[name] = {
            id,
            name,
            callback: functions[0],
            dispose: functions[1],
        };
        Logger.debug('Register step', id, name);
    }

    go(name, flowType = 'card', props) {
        if (!this.#steps[name]) return false;
        if (this.#steps[this.#currentStep]) this.#steps[this.#currentStep].dispose(!!props.keepCamera || false);

        const step = this.#steps[name];
        this.#currentStep = step.name;

        Dom.root().classList.remove('card', 'glass');
        Dom.root().classList.add(flowType);
        Dom.getAll('dock/step').forEach((el) => el.classList.remove('active'));
        Dom.getById('dock/step-' + step.id).classList.add('active');
        this.setStepperStep(step.id, flowType);
        Transformable.disableAll();
        Canvas.unsubscribeAll();
        Storage.set('flowType', flowType);
        Logger.debug('Go to step', step.id, name, '|', { flowType, ...props });
        step.callback({ flowType, ...props });
    }

    setStepperStep(stepId, flowType) {
        let allSteps = Dom.getAll('stepper/step');
        let mainStep = Dom.getBySelector(`[data-dom="stepper/step"][data-step-${flowType}][data-step-id="${stepId}"]`);
        let additionalStep = Dom.getBySelector(
            `[data-dom="stepper/step"][data-step-${flowType}][data-step-id2="${stepId}"]`,
        );
        if (!mainStep && additionalStep) {
            mainStep = additionalStep;
        } else if (!mainStep && !additionalStep) {
            return;
        }

        allSteps.forEach((step) => {
            step.classList.remove('active');
            if (step == mainStep || mainStep.dataset.stepDeps.split(',').includes(step.dataset.stepId)) {
                step.classList.add('active');
            }
        });
    }

    resetStepperUi() {
        let allSteps = Dom.getAll('stepper/step');
        allSteps.forEach((step) => {
            step.classList.remove('active');
        });
    }

    _dumpSteps() {
        console.groupCollapsed('Debug steps dump');
        Object.keys(this.#steps).forEach((key) => console.log(this.#steps[key]));
        console.groupEnd();
    }
}

const stepsManager = new StepsManager();
export default stepsManager;
