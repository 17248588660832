import Canvas from '../canvas';
import Storage from '../storage';
import Transformable from '../transformable';
import Dom from '../dom';
import StepsManager from '../steps';
import Calc from '../calc';
import Logger from '../logger';

let transformableListeners = {};
let handleContinueBtnClick;
let handleStep1BtnClick;
let handleStep2BtnClick;
let handleStep5BtnClick;
let drawLoop;
let faceBox;

function callback(props) {
    this.props = props;
    handleContinueBtnClick = _handleContinueBtnClick.bind(this);
    handleStep1BtnClick = _handleStep1BtnClick.bind(this);
    handleStep2BtnClick = _handleStep2BtnClick.bind(this);
    handleStep5BtnClick = _handleStep5BtnClick.bind(this);
    drawLoop = _drawLoop.bind(this);
    transformableListeners = Transformable.getListeners();

    Transformable.enable('pupils/adjustPupils/leftPupil');
    Transformable.enable('pupils/adjustPupils/rightPupil');

    Canvas.resizeToVideo();

    Canvas.subscribeToDrawLoop('stepPreviewGlasses', drawLoop);
    Canvas.subscribeToEvent('stepMeasureGlasses', 'mousedown', handleMousedownEvent);
    Canvas.subscribeToEvent('stepMeasureGlasses', 'touchstart', handleMousedownEvent);
    Canvas.subscribeToEvent('stepMeasureGlasses', 'mousemove', handleMousemoveEvent);
    Canvas.subscribeToEvent('stepMeasureGlasses', 'touchmove', handleMousemoveEvent);
    Canvas.subscribeToEvent('stepMeasureGlasses', 'mouseup', handleMouseupEvent);
    Canvas.subscribeToEvent('stepMeasureGlasses', 'touchend', handleMouseupEvent);
    Canvas.subscribeToEvent('stepMeasureGlasses', 'mouseleave', handleMouseleaveEvent);
    Canvas.subscribeToEvent('stepMeasureGlasses', 'touchcancel', handleMouseleaveEvent);

    faceBox = Calc.cropFaceBox(
        Storage.get('userPhoto/withoutAnything'),
        Storage.get('faces/photoWithoutAnything')[0],
        Canvas.width(),
        Canvas.height(),
    );
    Calc.convertPupilsToGlobalCoords('pupils/adjustPupils', faceBox);

    Canvas.enableDrawLoop();

    const continueBtn = Dom.getById('dock/step-4/continueBtn');
    continueBtn.addEventListener('click', handleContinueBtnClick);

    const step1Btn = Dom.getById('stepper/step-1');
    step1Btn.addEventListener('click', handleStep1BtnClick);

    const step2BtnCard = Dom.getById('stepper/step-2-card');
    step2BtnCard.addEventListener('click', handleStep2BtnClick);

    const step2BtnGlass = Dom.getById('stepper/step-2-glass');
    step2BtnGlass.addEventListener('click', handleStep2BtnClick);

    const step5Btn = Dom.getById('stepper/step-5');
    step5Btn.addEventListener('click', handleStep5BtnClick);
}

function dispose() {
    Canvas.disableDrawLoop();
    Canvas.clear();

    const continueBtn = Dom.getById('dock/step-4/continueBtn');
    continueBtn.removeEventListener('click', handleContinueBtnClick);

    const step1Btn = Dom.getById('stepper/step-1');
    step1Btn.removeEventListener('click', handleStep1BtnClick);

    const step2BtnCard = Dom.getById('stepper/step-2-card');
    step2BtnCard.removeEventListener('click', handleStep2BtnClick);

    const step2BtnGlass = Dom.getById('stepper/step-2-glass');
    step2BtnGlass.removeEventListener('click', handleStep2BtnClick);

    const step5Btn = Dom.getById('stepper/step-5');
    step5Btn.removeEventListener('click', handleStep5BtnClick);
}

function _handleContinueBtnClick(event) {
    Calc.convertPupilsToLocalCoords('pupils/adjustPupils', faceBox);

    const glassesData = Storage.getGlassesCalcData();
    const calculatedGlasses = Calc.calculateGlassesDimensions({
        cardPupils: glassesData.pupils.measureCard,
        glassesPupils: glassesData.pupils.measureGlasses,
        adjustPupils: glassesData.pupils.adjustPupils,
        glassesWidthMm: glassesData.glassesWidthMm,
        userGlassesWidthMm: glassesData.userData.glassesWidthMm,
        cardOutlineWidth: glassesData.objects.cardOutline.w,
        glassesOutlineWidth: glassesData.objects.glassesOutline.w,
        flowType: this.props.flowType,
    });

    let canvasWidth = Canvas.width();
    if (window.devicePixelRatio > 1) {
        canvasWidth /= window.devicePixelRatio;
    }
    let aspectRatio = +Storage.get('assets/glasses').naturalWidth / +Storage.get('assets/glasses').naturalHeight;
    Storage.set('objects/glassesPreview/w', Math.round(calculatedGlasses.w));
    Storage.set('objects/glassesPreview/x', canvasWidth / 2 - calculatedGlasses.w / 2);
    Storage.set('objects/glassesPreview/h', Math.round(calculatedGlasses.w / aspectRatio));
    Logger.debug('Calculated preview glasses:', calculatedGlasses);

    Storage.set('finished', true);
    StepsManager.go('stepPreviewGlasses', this.props.flowType, {});
}

function _handleStep1BtnClick() {
    StepsManager.go('stepTakePhotoWithObject', this.props.flowType, {});
}

function _handleStep2BtnClick() {
    if (this.props.flowType == 'card') {
        StepsManager.go('stepMeasureCard', 'card', { keepCardPos: true });
    } else if (this.props.flowType == 'glass') {
        StepsManager.go('stepMeasureGlasses', 'glass', { keepGlassesPos: true });
    }
}

function _handleStep5BtnClick() {
    const continueBtn = Dom.getById('dock/step-4/continueBtn');
    continueBtn.click();
}

function _drawLoop(ctx) {
    Canvas.clear();

    ctx.drawImage(
        Storage.get('userPhoto/withoutAnything').imageObject,
        faceBox.cropped.x,
        faceBox.cropped.y,
        faceBox.cropped.w,
        faceBox.cropped.h,
    );
    Canvas.drawDebugOutline('red', faceBox.face);
    Canvas.drawDebugOutline('blue', faceBox.newFace);
    Canvas.drawDebugOutline('green', {
        x: 0,
        y: 0,
        w: faceBox.offset.x,
        h: faceBox.offset.y,
    });
    Canvas.drawDebugOutline('orange', faceBox.cropped);
    Canvas.drawPupils('pupils/adjustPupils');

    Transformable.drawLoop(ctx);
}

function handleMousedownEvent(event) {
    transformableListeners.mousedown(event);
}

function handleMousemoveEvent(event) {
    transformableListeners.mousemove(event);
}

function handleMouseupEvent(event) {
    transformableListeners.mouseup(event);
}

function handleMouseleaveEvent(event) {
    transformableListeners.mouseleave(event);
}

export default [callback, dispose];
