import Dom from './dom';
import Logger from './logger';
import Storage from './storage';

class Canvas {
    #canvas;
    #ctx;
    #eventSubscribers;
    #drawLoopSubscribers;
    #drawLoopEnabled = false;
    #debugMode = false;

    #bound_handleMousedownEvent;
    #bound_handleTouchstartEvent;
    #bound_handleMousemoveEvent;
    #bound_handleTouchmoveEvent;
    #bound_handleMouseupEvent;
    #bound_handleTouchendEvent;
    #bound_handleMouseleaveEvent;
    #bound_handleTouchCancelEvent;
    #bound_drawLoop;

    constructor() {
        this.#eventSubscribers = [];
        this.#drawLoopSubscribers = [];
        this.#drawLoopEnabled = false;
    }

    init(debugMode = true) {
        this.#canvas = Dom.getById('main-canvas');
        this.#ctx = this.#canvas.getContext('2d');
        this.#bound_handleMousedownEvent = this.#handleMousedownEvent.bind(this);
        this.#bound_handleTouchstartEvent = this.#handleTouchstartEvent.bind(this);
        this.#bound_handleMousemoveEvent = this.#handleMousemoveEvent.bind(this);
        this.#bound_handleTouchmoveEvent = this.#handleTouchmoveEvent.bind(this);
        this.#bound_handleMouseupEvent = this.#handleMouseupEvent.bind(this);
        this.#bound_handleTouchendEvent = this.#handleTouchendEvent.bind(this);
        this.#bound_handleMouseleaveEvent = this.#handleMouseleaveEvent.bind(this);
        this.#bound_handleTouchCancelEvent = this.#handleTouchCancelEvent.bind(this);
        this.#bound_drawLoop = this.#drawLoop.bind(this);
        this.handleResizeEvent = this.#unbound_handleResizeEvent.bind(this);
        this.#debugMode = debugMode;

        this.#canvas.addEventListener('mousedown', this.#bound_handleMousedownEvent);
        this.#canvas.addEventListener('touchstart', this.#bound_handleTouchstartEvent);
        this.#canvas.addEventListener('mousemove', this.#bound_handleMousemoveEvent);
        this.#canvas.addEventListener('touchmove', this.#bound_handleTouchmoveEvent);
        this.#canvas.addEventListener('mouseup', this.#bound_handleMouseupEvent);
        this.#canvas.addEventListener('touchend', this.#bound_handleTouchendEvent);
        this.#canvas.addEventListener('mouseleave', this.#bound_handleMouseleaveEvent);
        this.#canvas.addEventListener('touchcancel', this.#bound_handleTouchCancelEvent);
    }

    width(width = 0) {
        if (width != 0) this.#canvas.width = width;
        return this.#canvas.width;
    }

    height(height = 0) {
        if (height != 0) this.#canvas.height = height;
        return this.#canvas.height;
    }

    rect() {
        return this.#canvas.getBoundingClientRect();
    }

    clear() {
        this.#ctx.clearRect(0, 0, this.#canvas.width, this.#canvas.height);
    }

    async toDataURL(mime = 'image/jpeg', quality = 0.9) {
        return await this.#canvas.toDataURL(mime, quality);
    }

    subscribeToEvent(id, event, callback) {
        this.#eventSubscribers.push({
            id,
            event,
            callback,
        });
        Logger.debug('Subscribe to canvas event', event, id);
    }

    subscribeToDrawLoop(id, callback) {
        this.#drawLoopSubscribers.push({
            id,
            callback,
        });
        Logger.debug('Subscribe to canvas draw loop', id);
    }

    unsubscribeAll() {
        this.#drawLoopEnabled = false;
        this.#eventSubscribers = [];
        this.#drawLoopSubscribers = [];
        Logger.debug('Unsubscribe all from canvas events and draw loop');
    }

    enableDrawLoop() {
        Logger.debug('Canvas enable draw loop');
        this.#drawLoopEnabled = true;
        this.#drawLoop();
    }

    disableDrawLoop() {
        Logger.debug('Canvas disable draw loop');
        this.#drawLoopEnabled = false;
    }

    resizeToVideo() {
        const video = Dom.getById('main-video');
        let newWidth = video.clientWidth;
        let newHeight = video.clientHeight;
        Logger.debug('Resize canvas');
        this.#canvas.style.width = null;
        this.#canvas.style.height = null;

        if (window.devicePixelRatio > 1) {
            let oldWidth = video.clientWidth;
            let oldHeight = video.clientHeight;
            Logger.debug('Retina detected! Pixel ratio:', window.devicePixelRatio);
            Logger.debug('Old canvas dimensions:', oldWidth, oldHeight);

            newWidth = oldWidth * window.devicePixelRatio;
            newHeight = oldHeight * window.devicePixelRatio;
            this.width(newWidth);
            this.height(newHeight);
            this.#canvas.style.width = oldWidth + 'px';
            this.#canvas.style.height = oldHeight + 'px';

            this.#ctx.scale(window.devicePixelRatio, window.devicePixelRatio);
        } else {
            this.width(newWidth);
            this.height(newHeight);
        }
        Logger.debug('New canvas dimensions:', newWidth, newHeight);
    }

    drawPupils(objectName) {
        this.#ctx.save();

        const pupilSize = +Storage.get('const/pupilSize');
        const halfPupilSize = pupilSize / 2;

        const leftX = +Storage.get(objectName + '/leftPupil/x');
        const leftY = +Storage.get(objectName + '/leftPupil/y');

        const rightX = +Storage.get(objectName + '/rightPupil/x');
        const rightY = +Storage.get(objectName + '/rightPupil/y');

        this.#ctx.drawImage(
            Storage.get('assets/pupil'),
            leftX - halfPupilSize,
            leftY - halfPupilSize,
            pupilSize,
            pupilSize,
        );

        this.#ctx.drawImage(
            Storage.get('assets/pupil'),
            rightX - halfPupilSize,
            rightY - halfPupilSize,
            pupilSize,
            pupilSize,
        );

        this.#ctx.restore();
    }

    drawCardOutline(objectName) {
        const borderWidth = 3;
        const [x, y, w, h] = [
            +Storage.get(objectName + '/x'),
            +Storage.get(objectName + '/y'),
            +Storage.get(objectName + '/w'),
            +Storage.get(objectName + '/h'),
        ];
        this.#ctx.save();

        // Set styles for dashed line
        this.#ctx.strokeStyle = '#ffffff';
        this.#ctx.lineWidth = 2;
        this.#ctx.setLineDash([2, 5]);

        // Draw left dashed line
        this.#ctx.beginPath();
        this.#ctx.moveTo(x, 0);
        this.#ctx.lineTo(x, y + h);

        // Draw right dashed line
        this.#ctx.moveTo(x + w, 0);
        this.#ctx.lineTo(x + w, y + h);

        // Draw bottom dashed line
        this.#ctx.moveTo(0, y + h);
        this.#ctx.lineTo(this.width(), y + h);
        this.#ctx.stroke();

        if (!Storage.get(objectName + '/_customType')) {
            // Set styles for border
            this.#ctx.strokeStyle = '#DB4141';
            this.#ctx.lineWidth = borderWidth * 2;
            this.#ctx.setLineDash([]);

            // Draw left border
            this.#ctx.beginPath();
            this.#ctx.moveTo(x, y);
            this.#ctx.lineTo(x, y + h + borderWidth);

            // Draw bottom border
            this.#ctx.moveTo(x - borderWidth, y + h);
            this.#ctx.lineTo(x + w + borderWidth, y + h);

            // Draw right border
            this.#ctx.moveTo(x + w, y + h + borderWidth);
            this.#ctx.lineTo(x + w, y);
            this.#ctx.stroke();
        }

        this.#ctx.restore();
    }

    drawGlassesOutline(objectName) {
        const borderWidth = 3;
        const [x, y, w, h] = [
            +Storage.get(objectName + '/x'),
            +Storage.get(objectName + '/y'),
            +Storage.get(objectName + '/w'),
            +Storage.get(objectName + '/h'),
        ];
        this.#ctx.save();

        // Set styles for dashed line
        this.#ctx.strokeStyle = '#ffffff';
        this.#ctx.lineWidth = 2;
        this.#ctx.setLineDash([2, 5]);

        // Draw left dashed line
        this.#ctx.beginPath();
        this.#ctx.moveTo(x, 0);
        this.#ctx.lineTo(x, this.height());

        // Draw right dashed line
        this.#ctx.moveTo(x + w, 0);
        this.#ctx.lineTo(x + w, this.height());
        this.#ctx.stroke();

        if (!Storage.get(objectName + '/_translate') && !Storage.get(objectName + '/_scale')) {
            // Set styles for border
            this.#ctx.strokeStyle = '#DB4141';
            this.#ctx.lineWidth = borderWidth * 2;
            this.#ctx.setLineDash([]);

            // Draw left border
            this.#ctx.beginPath();
            this.#ctx.moveTo(x - borderWidth, y);
            this.#ctx.lineTo(x - borderWidth, y + h);

            // Draw bottom border
            this.#ctx.moveTo(x, y + h / 2);
            this.#ctx.lineTo(x + w, y + h / 2);

            // Draw right border
            this.#ctx.moveTo(x + w + borderWidth, y + h);
            this.#ctx.lineTo(x + w + borderWidth, y);
            this.#ctx.stroke();
        }

        if (this.#debugMode) {
            this.#ctx.strokeStyle = '#00ff00';
            this.#ctx.lineWidth = 1;
            this.#ctx.setLineDash([0, 0]);

            this.#ctx.beginPath();
            this.#ctx.moveTo(x, 0);
            this.#ctx.lineTo(x, this.height());

            this.#ctx.moveTo(x + w, 0);
            this.#ctx.lineTo(x + w, this.height());
            this.#ctx.stroke();
        }

        this.#ctx.restore();
    }

    drawPreviewGlasses(objectName) {
        const [x, y, w, h] = [
            +Storage.get(objectName + '/x'),
            +Storage.get(objectName + '/y'),
            +Storage.get(objectName + '/w'),
            +Storage.get(objectName + '/h'),
        ];
        this.#ctx.save();

        this.#ctx.drawImage(Storage.get('assets/glasses'), x, y, w, h);

        this.#ctx.restore();
    }

    drawDebugOutline(color = 'red', coords) {
        if (!this.#debugMode) return;

        this.#ctx.save();

        this.#ctx.lineWidth = 2;
        this.#ctx.strokeStyle = color;
        this.#ctx.strokeRect(coords.x, coords.y, coords.w, coords.h);

        this.#ctx.restore();
    }

    #unbound_handleResizeEvent() {
        this.resizeToVideo();
        if (!this.#drawLoopEnabled) {
            this.#drawLoopEnabled = true;
            this.#drawLoop();
            this.#drawLoopEnabled = false;
        }
    }

    #notifyEventSubscribers(eventName, eventPayload) {
        this.#eventSubscribers.forEach((subscriber) => {
            if (subscriber.event === eventName) {
                subscriber.callback(eventPayload);
            }
        });
    }

    #notifyDrawLoopSubscribers(context) {
        this.#drawLoopSubscribers.forEach((subscriber) => {
            subscriber.callback(context);
        });
    }

    #handleMousedownEvent(event) {
        this.#notifyEventSubscribers('mousedown', event);
    }

    #handleTouchstartEvent(event) {
        this.#notifyEventSubscribers('touchstart', event);
    }

    #handleMousemoveEvent(event) {
        this.#notifyEventSubscribers('mousemove', event);
    }

    #handleTouchmoveEvent(event) {
        this.#notifyEventSubscribers('touchmove', event);
    }

    #handleMouseupEvent(event) {
        this.#notifyEventSubscribers('mouseup', event);
    }

    #handleTouchendEvent(event) {
        this.#notifyEventSubscribers('touchend', event);
    }

    #handleMouseleaveEvent(event) {
        this.#notifyEventSubscribers('mouseleave', event);
    }

    #handleTouchCancelEvent(event) {
        this.#notifyEventSubscribers('touchcancel', event);
    }

    #drawLoop() {
        if (!this.#drawLoopEnabled) return;
        window.requestAnimationFrame(this.#bound_drawLoop);
        this.#notifyDrawLoopSubscribers(this.#ctx);
    }
}

const canvas = new Canvas();
export default canvas;
