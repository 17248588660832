import Storage from './storage';
import Logger from './logger';
import Canvas from './canvas';
import Calc from './calc';

let [cx, cy] = [0, 0];

function getCanvasCursor(event) {
    let cx = 0;
    let cy = 0;
    if (!event) return [0, 0];

    let rect = Canvas.rect();
    let diffX = 1; //(Canvas.width() / rect.width) / window.devicePixelRatio;
    let diffY = 1; //(Canvas.height() / rect.height) / window.devicePixelRatio;
    if (event.targetTouches) {
        cx = event.targetTouches[0].clientX - rect.left;
        cy = event.targetTouches[0].clientY - rect.top;
    } else {
        cx = (event.pageX - rect.x) * diffX;
        cy = (event.pageY - rect.y) * diffY;
    }

    return [cx, cy];
}

function getControlPos(instance, control) {
    const BASE_CONTROL_SIZE = 44;
    let controlW;
    let controlH;

    let pos = {
        x: 0,
        y: 0,
        w: controlW,
        h: controlH,
        offX: 0,
        offY: 0,
    };

    if (instance && control) {
        let instanceW = +Storage.get(instance.objectName + '/w');
        let instanceH = +Storage.get(instance.objectName + '/h');
        let instanceX = +Storage.get(instance.objectName + '/x');
        let instanceY = +Storage.get(instance.objectName + '/y');

        switch (control.pos) {
            case 'left-top':
                controlW = BASE_CONTROL_SIZE;
                controlH = BASE_CONTROL_SIZE;

                pos.offX = controlW + control.offsetX;
                pos.offY = controlH + control.offsetY;

                if (instance.centered) {
                    pos.offX += instanceW / 2;
                    pos.offY += instanceH / 2;
                }

                pos.x = instanceX - pos.offX;
                pos.y = instanceY - pos.offY;
                break;

            case 'center-top':
                controlW = BASE_CONTROL_SIZE;
                controlH = 54;

                pos.offX = -1 * (instanceW / 2 - controlW / 2 + control.offsetX);
                pos.offY = controlH + control.offsetY;

                pos.x = instanceX - pos.offX;
                pos.y = instanceY - pos.offY;
                break;

            case 'right-top':
                controlW = BASE_CONTROL_SIZE;
                controlH = BASE_CONTROL_SIZE;

                pos.offX = -1 * (instanceW + control.offsetX);
                pos.offY = controlH + control.offsetY;

                if (instance.centered) {
                    pos.offX += instanceW / 2;
                    pos.offY += instanceH / 2;
                }

                pos.x = instanceX - pos.offX;
                pos.y = instanceY - pos.offY;
                break;

            case 'right-center':
                controlW = 54;
                controlH = BASE_CONTROL_SIZE;

                pos.offX = -1 * (instanceW + control.offsetX);
                pos.offY = -1 * (instanceH / 2 - controlH / 2 - control.offsetY);

                pos.x = instanceX - pos.offX;
                pos.y = instanceY - pos.offY;
                break;

            case 'center-bottom':
                controlW = BASE_CONTROL_SIZE;
                controlH = 54;

                pos.offX = -1 * (instanceW / 2 - controlW / 2 + control.offsetX);
                pos.offY = -1 * (instanceH + control.offsetY);

                pos.x = instanceX - pos.offX;
                pos.y = instanceY - pos.offY;
                break;

            case 'left-center':
                controlW = 54;
                controlH = BASE_CONTROL_SIZE;

                pos.offX = controlW + control.offsetX;
                pos.offY = -1 * (instanceH / 2 - controlH / 2 - control.offsetY);

                pos.x = instanceX - pos.offX;
                pos.y = instanceY - pos.offY;
                break;

            case 'all':
                controlW = instanceW;
                controlH = instanceH;
                pos.x = instanceX;
                pos.y = instanceY;
                break;

            default:
                return;
        }
    }

    pos.w = controlW;
    pos.h = controlH;

    return pos;
}

class Transformable {
    #instances;

    #bound_handleMousedownEvent;
    #bound_handleMousemoveEvent;
    #bound_handleMouseupEvent;
    #bound_handleMouseleaveEvent;

    constructor() {
        this.#instances = {};
        this.#bound_handleMousedownEvent = this.#handleMousedownEvent.bind(this);
        this.#bound_handleMousemoveEvent = this.#handleMousemoveEvent.bind(this);
        this.#bound_handleMouseupEvent = this.#handleMouseupEvent.bind(this);
        this.#bound_handleMouseleaveEvent = this.#handleMouseleaveEvent.bind(this);
    }

    add(objectName, controls = [], centered = false) {
        this.#instances[objectName] = {
            objectName,
            controls,
            centered,
            enabled: false,
        };
        Logger.debug('Add transformable', objectName);
    }

    remove(objectName) {
        delete this.#instances[objectName];
        Logger.debug('Remove transformable', objectName);
    }

    enable(objectName) {
        if (this.#instances[objectName]) {
            this.#instances[objectName].enabled = true;
            Logger.debug('Enable transformable', objectName);
        }
    }

    enableAll() {
        Object.keys(this.#instances).forEach((key) => {
            this.#instances[key].enabled = true;
        });
    }

    disable(objectName) {
        if (this.#instances[objectName]) {
            this.#instances[objectName].enabled = false;
            Logger.debug('Disable transformable', objectName);
        }
    }

    disableAll() {
        Object.keys(this.#instances).forEach((key) => {
            this.#instances[key].enabled = false;
        });
    }

    clearAll() {
        this.#instances = {};
    }

    getListeners() {
        return {
            mousedown: this.#bound_handleMousedownEvent,
            mousemove: this.#bound_handleMousemoveEvent,
            mouseup: this.#bound_handleMouseupEvent,
            mouseleave: this.#bound_handleMouseleaveEvent,
        };
    }

    drawLoop(ctx) {
        Object.keys(this.#instances).forEach((key) => {
            const instance = this.#instances[key];
            if (!instance.enabled) return;

            instance.controls.forEach((control) => {
                if (!control.enabled) return;

                /**
                 * Order of controls processing:
                 * [1] [2] [3]
                 * [8] [ ] [4]
                 * [7] [6] [5]
                 */

                const controlPos = getControlPos(instance, control);
                switch (control.pos) {
                    case 'left-top':
                        ctx.drawImage(Storage.get('assets/drag-any-angled'), controlPos.x, controlPos.y);
                        break;

                    case 'center-top':
                        if (control.type == 'translate')
                            ctx.drawImage(Storage.get('assets/drag-any'), controlPos.x, controlPos.y);
                        else if (control.type == 'scale')
                            ctx.drawImage(Storage.get('assets/drag-vertical'), controlPos.x, controlPos.y);
                        break;

                    case 'right-top':
                        ctx.drawImage(Storage.get('assets/drag-any-angled-rev'), controlPos.x, controlPos.y);
                        break;

                    case 'right-center':
                        if (control.type == 'translate' || control.type == 'scale') {
                            ctx.drawImage(Storage.get('assets/drag-horizontal-rev'), controlPos.x, controlPos.y);
                        } else if (control.type == 'custom-hs-vt') {
                            ctx.drawImage(Storage.get('assets/drag-any-h-rev'), controlPos.x, controlPos.y);
                        }
                        break;

                    case 'center-bottom':
                        if (control.type == 'translate')
                            ctx.drawImage(Storage.get('assets/drag-any-rev'), controlPos.x, controlPos.y);
                        else if (control.type == 'scale')
                            ctx.drawImage(Storage.get('assets/drag-vertical-rev'), controlPos.x, controlPos.y);
                        break;

                    case 'left-center':
                        if (control.type == 'translate' || control.type == 'scale') {
                            ctx.drawImage(Storage.get('assets/drag-horizontal'), controlPos.x, controlPos.y);
                        } else if (control.type == 'custom-hs-vt') {
                            ctx.drawImage(Storage.get('assets/drag-any-h'), controlPos.x, controlPos.y);
                        }
                        break;

                    default:
                        return;
                }
            });
        });
    }

    #handleMousedownEvent(event) {
        [cx, cy] = getCanvasCursor(event);

        Object.keys(this.#instances).forEach((key) => {
            const instance = this.#instances[key];
            if (!instance.enabled) return;

            instance.controls.forEach((control) => {
                if (!control.enabled) return;

                /**
                 * Order of controls processing:
                 * [1] [2] [3]
                 * [8] [ ] [4]
                 * [7] [6] [5]
                 */
                const controlPos = getControlPos(instance, control);
                const collision = Calc.pointRectCollision(
                    cx,
                    cy,
                    controlPos.x,
                    controlPos.y,
                    controlPos.w,
                    controlPos.h,
                );

                if (collision) {
                    if (control.type === 'translate') {
                        Storage.set(instance.objectName + '/_translate', true);
                        Storage.set(instance.objectName + '/_translateControl', control.pos);
                    } else if (control.type === 'scale') {
                        Storage.set(instance.objectName + '/_scale', true);
                        Storage.set(instance.objectName + '/_scaleControl', control.pos);
                    } else {
                        Storage.set(instance.objectName + '/_customType', control.type);
                        Storage.set(instance.objectName + '/_customControl', control.pos);
                    }

                    Storage.set(instance.objectName + '/_sx', cx - controlPos.x);
                    Storage.set(instance.objectName + '/_sy', cy - controlPos.y);

                    Storage.set(instance.objectName + '/_origx', Storage.get(instance.objectName + '/x'));
                    Storage.set(instance.objectName + '/_origy', Storage.get(instance.objectName + '/y'));

                    Storage.set(instance.objectName + '/_origw', Storage.get(instance.objectName + '/w'));
                    Storage.set(instance.objectName + '/_origh', Storage.get(instance.objectName + '/h'));
                }
            });
        });
    }

    #handleMousemoveEvent(event) {
        [cx, cy] = getCanvasCursor(event);
        let [canvasW, canvasH] = [Canvas.width(), Canvas.height()];

        Object.keys(this.#instances).forEach((key) => {
            const instance = this.#instances[key];
            if (!instance.enabled) return;

            let activeControlPos = false;
            if (Storage.get(instance.objectName + '/_translate')) {
                activeControlPos = Storage.get(instance.objectName + '/_translateControl');
            } else if (Storage.get(instance.objectName + '/_scale')) {
                activeControlPos = Storage.get(instance.objectName + '/_scaleControl');
            } else if (Storage.get(instance.objectName + '/_customType')) {
                activeControlPos = Storage.get(instance.objectName + '/_customControl');
            }
            if (!activeControlPos) return;

            instance.controls.forEach((control) => {
                if (!control.enabled) return;
                if (control.pos !== activeControlPos) return;

                const controlPos = getControlPos(instance, control);
                let oldX = +Storage.get(instance.objectName + '/x');
                let oldY = +Storage.get(instance.objectName + '/y');
                let oldW = +Storage.get(instance.objectName + '/w');
                let oldH = +Storage.get(instance.objectName + '/h');

                let origX = +Storage.get(instance.objectName + '/_origx');
                let origY = +Storage.get(instance.objectName + '/_origy');
                let origW = +Storage.get(instance.objectName + '/_origw');
                let origH = +Storage.get(instance.objectName + '/_origh');

                let newX = oldX;
                let newY = oldY;
                let newW = oldW;
                let newH = oldH;

                switch (control.pos) {
                    case 'left-top':
                        newX = cx - +Storage.get(instance.objectName + '/_sx') + controlPos.offX;
                        newY = cy - +Storage.get(instance.objectName + '/_sy') + controlPos.offY;
                        break;

                    case 'center-top':
                        if (control.type === 'translate') {
                            newX = cx - +Storage.get(instance.objectName + '/_sx') + controlPos.offX;
                            newY = cy - +Storage.get(instance.objectName + '/_sy') + controlPos.offY;
                        }
                        break;

                    case 'right-top':
                        newX = cx - +Storage.get(instance.objectName + '/_sx') + controlPos.offX;
                        newY = cy - +Storage.get(instance.objectName + '/_sy') + controlPos.offY;
                        break;

                    case 'right-center':
                        let diffX = cx - +Storage.get(instance.objectName + '/_sx') + controlPos.offX;
                        if (control.type === 'scale') {
                            newW = oldW - (oldX - diffX);
                        } else if (control.type === 'custom-hs-vt') {
                            newW = oldW - (oldX - diffX);
                            newY = cy - +Storage.get(instance.objectName + '/_sy') + controlPos.offY;
                        }
                        break;

                    case 'center-bottom':
                        if (control.type === 'translate') {
                            newX = cx - +Storage.get(instance.objectName + '/_sx') + controlPos.offX;
                            newY = cy - +Storage.get(instance.objectName + '/_sy') + controlPos.offY;
                        }
                        break;

                    case 'left-center':
                        newX = cx - +Storage.get(instance.objectName + '/_sx') + controlPos.offX;
                        if (control.type === 'scale') {
                            newW += oldX - newX;
                        } else if (control.type === 'custom-hs-vt') {
                            newW += oldX - newX;
                            newY = cy - +Storage.get(instance.objectName + '/_sy') + controlPos.offY;
                        }
                        break;

                    case 'all':
                        if (control.type === 'translate') {
                            newX = cx - +Storage.get(instance.objectName + '/_sx') + controlPos.offX;
                            newY = cy - +Storage.get(instance.objectName + '/_sy') + controlPos.offY;
                        }
                        break;

                    default:
                        return;
                }

                newX = Calc.clamp(newX, 0, canvasW);
                newY = Calc.clamp(newY, 0, canvasH);
                newW = Calc.clamp(newW, 0, canvasW);
                newH = Calc.clamp(newH, 0, canvasH);

                Storage.set(instance.objectName + '/x', newX);
                Storage.set(instance.objectName + '/y', newY);
                Storage.set(instance.objectName + '/w', newW);
                Storage.set(instance.objectName + '/h', newH);
            });
        });
    }

    #handleMouseupEvent(event) {
        Object.keys(this.#instances).forEach((key) => {
            const instance = this.#instances[key];

            Storage.set(instance.objectName + '/_translate', false);
            Storage.set(instance.objectName + '/_translateControl', false);
            Storage.set(instance.objectName + '/_scale', false);
            Storage.set(instance.objectName + '/_scaleControl', false);
            Storage.set(instance.objectName + '/_customType', false);
            Storage.set(instance.objectName + '/_customControl', false);
        });
    }

    #handleMouseleaveEvent(event) {
        Object.keys(this.#instances).forEach((key) => {
            const instance = this.#instances[key];

            Storage.set(instance.objectName + '/_translate', false);
            Storage.set(instance.objectName + '/_translateControl', false);
            Storage.set(instance.objectName + '/_scale', false);
            Storage.set(instance.objectName + '/_scaleControl', false);
            Storage.set(instance.objectName + '/_customType', false);
            Storage.set(instance.objectName + '/_customControl', false);
        });
    }
}

const transformable = new Transformable();
export default transformable;
