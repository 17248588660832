import { RekognitionClient, DetectFacesCommand } from '@aws-sdk/client-rekognition';
import { fromEnv } from '@aws-sdk/credential-provider-env';

import Logger from './logger';
import { Buffer as XBuffer } from 'buffer';

class AWS {
    #rekognition;

    constructor() {
        this.#rekognition = new RekognitionClient({
            region: import.meta.env.VITE_AWS_REGION,
            credentials: fromEnv(),
        });
    }

    async detectFaces(imageBytes, imageWidth = 1, imageHeight = 1) {
        const params = {
            Image: {
                Bytes: XBuffer.from(imageBytes, 'base64'),
            },
            Attributes: ['ALL'],
        };

        const detectFacesCmd = new DetectFacesCommand(params);
        return new Promise((resolve, reject) => {
            this.#rekognition.send(detectFacesCmd).then(
                (data) => {
                    const faces = this.#transformFaceDetails(data, imageWidth, imageHeight);
                    Logger.debug('AWS recognized faces', faces);
                    resolve(faces);
                },
                (error) => {
                    reject(error);
                },
            );
        });
    }

    #transformFaceDetails(response, imageWidth = 1, imageHeight = 1) {
        const x = (num) => num * imageWidth;
        const y = (num) => num * imageHeight;
        const lineDist = (a, b) => Math.abs(a - b);

        let details = [];
        if (response.FaceDetails) {
            response.FaceDetails.forEach((face) => {
                let leftEyeUp = face.Landmarks.find((x) => x.Type == 'leftEyeUp');
                let leftEyeRight = face.Landmarks.find((x) => x.Type == 'leftEyeRight');
                let leftEyeDown = face.Landmarks.find((x) => x.Type == 'leftEyeDown');
                let leftEyeLeft = face.Landmarks.find((x) => x.Type == 'leftEyeLeft');

                let rightEyeUp = face.Landmarks.find((x) => x.Type == 'rightEyeUp');
                let rightEyeRight = face.Landmarks.find((x) => x.Type == 'rightEyeRight');
                let rightEyeDown = face.Landmarks.find((x) => x.Type == 'rightEyeDown');
                let rightEyeLeft = face.Landmarks.find((x) => x.Type == 'rightEyeLeft');

                let leftPupil = face.Landmarks.find((x) => x.Type == 'leftPupil');
                let rightPupil = face.Landmarks.find((x) => x.Type == 'rightPupil');

                details.push({
                    faceBox: {
                        x: x(face.BoundingBox.Left),
                        y: y(face.BoundingBox.Top),
                        w: x(face.BoundingBox.Width),
                        h: y(face.BoundingBox.Height),
                        dist: x(face.BoundingBox.Width),
                    },
                    leftEye: {
                        upX: x(leftEyeUp.X),
                        upY: y(leftEyeUp.Y),
                        rightX: x(leftEyeRight.X),
                        rightY: y(leftEyeRight.Y),
                        downX: x(leftEyeDown.X),
                        downY: y(leftEyeDown.Y),
                        leftX: x(leftEyeLeft.X),
                        leftY: y(leftEyeLeft.Y),
                        w: lineDist(x(leftEyeLeft.X), x(leftEyeRight.X)),
                        h: lineDist(y(leftEyeUp.Y), y(leftEyeDown.Y)),
                    },
                    rightEye: {
                        upX: x(rightEyeUp.X),
                        upY: y(rightEyeUp.Y),
                        rightX: x(rightEyeRight.X),
                        rightY: y(rightEyeRight.Y),
                        downX: x(rightEyeDown.X),
                        downY: y(rightEyeDown.Y),
                        leftX: x(rightEyeLeft.X),
                        leftY: y(rightEyeLeft.Y),
                        w: lineDist(x(rightEyeLeft.X), x(rightEyeRight.X)),
                        h: lineDist(y(rightEyeUp.Y), y(rightEyeDown.Y)),
                    },
                    leftPupil: {
                        x: x(leftPupil.X),
                        y: y(leftPupil.Y),
                    },
                    rightPupil: {
                        x: x(rightPupil.X),
                        y: y(rightPupil.Y),
                    },
                });
            });
        }

        return details;
    }
}

const aws = new AWS();
export default aws;
