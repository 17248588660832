import Camera from '../camera';
import Dom from '../dom';
import Logger from '../logger';
import AWS from '../aws';
import Loader from '../loader';
import Storage from '../storage';
import StepsManager from '../steps';

let handleCameraBtnClick;
let handleStep1BtnClick;
let handleStep2BtnClick;
let handleStep5BtnClick;

function callback(props) {
    Camera.play(true);
    this.props = props;
    handleCameraBtnClick = _handleCameraBtnClick.bind(this);
    handleStep1BtnClick = _handleStep1BtnClick.bind(this);
    handleStep2BtnClick = _handleStep2BtnClick.bind(this);
    handleStep5BtnClick = _handleStep5BtnClick.bind(this);

    const cameraBtn = Dom.getById('dock/step-3/cameraBtn');
    cameraBtn.addEventListener('click', handleCameraBtnClick);

    const step1Btn = Dom.getById('stepper/step-1');
    step1Btn.addEventListener('click', handleStep1BtnClick);

    const step2BtnCard = Dom.getById('stepper/step-2-card');
    step2BtnCard.addEventListener('click', handleStep2BtnClick);

    const step2BtnGlass = Dom.getById('stepper/step-2-glass');
    step2BtnGlass.addEventListener('click', handleStep2BtnClick);

    const step5Btn = Dom.getById('stepper/step-5');
    step5Btn.addEventListener('click', handleStep5BtnClick);
}

function dispose(keepCamera = false) {
    const cameraBtn = Dom.getById('dock/step-3/cameraBtn');
    cameraBtn.removeEventListener('click', handleCameraBtnClick);
    if (!keepCamera) {
        Camera.pause();
        Camera.closeStream();
    }

    const step1Btn = Dom.getById('stepper/step-1');
    step1Btn.removeEventListener('click', handleStep1BtnClick);

    const step2BtnCard = Dom.getById('stepper/step-2-card');
    step2BtnCard.removeEventListener('click', handleStep2BtnClick);

    const step2BtnGlass = Dom.getById('stepper/step-2-glass');
    step2BtnGlass.removeEventListener('click', handleStep2BtnClick);

    const step5Btn = Dom.getById('stepper/step-5');
    step5Btn.removeEventListener('click', handleStep5BtnClick);
}

function _handleCameraBtnClick() {
    Loader.show('Taking photo...', true);
    Camera.pause();
    Camera.takePhoto().then((photo) => {
        Camera.pause();
        Storage.set('userPhoto/withoutAnything', photo);

        Logger.debug('Camera photo data', photo);
        Loader.updateText('Analyzing image...');

        AWS.detectFaces(photo.dataUrl.replace(/^data:image\/(png|jpeg|jpg);base64,/, ''), photo.width, photo.height)
            .then((faces) => {
                Storage.set('faces/photoWithoutAnything', faces);

                Storage.set('pupils/adjustPupils/leftPupil/x', faces[0].leftPupil.x);
                Storage.set('pupils/adjustPupils/leftPupil/y', faces[0].leftPupil.y);

                Storage.set('pupils/adjustPupils/rightPupil/x', faces[0].rightPupil.x);
                Storage.set('pupils/adjustPupils/rightPupil/y', faces[0].rightPupil.y);

                Loader.updateText('Done!');
                Loader.hide();

                Camera.closeStream();
                StepsManager.go('stepAdjustPupils', this.props.flowType, {});
            })
            .catch((error) => {
                Camera.closeStream();
                Loader.setErrorMode('Try again', () => {
                    Loader.hide();
                    Loader.setLoadingMode();
                    StepsManager.go('stepTakePhotoWithoutAnything', this.props.flowType, {});
                });
                Loader.updateText(
                    'We didnt recognize the pupils on your photo.\nPlease retake photo',
                    'Something went wrong...',
                );
            });
    });
}

function _handleStep1BtnClick() {
    StepsManager.go('stepTakePhotoWithObject', this.props.flowType, { keepCamera: true });
}

function _handleStep2BtnClick() {
    if (this.props.flowType == 'card') {
        StepsManager.go('stepMeasureCard', 'card', { keepCardPos: true });
    } else if (this.props.flowType == 'glass') {
        StepsManager.go('stepMeasureGlasses', 'glass', { keepGlassesPos: true });
    }
}

function _handleStep5BtnClick() {
    const cameraBtn = Dom.getById('dock/step-3/cameraBtn');
    cameraBtn.click();
}

export default [callback, dispose];
